import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import './informes.scss'
import ModalGuardarSeleccionInformePersonalizadoPage from '../../containers/informes/ModalGuardarSeleccionInformePersonalizadoPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import { Delete, Launch, Print, RemoveRedEye, Save } from '@material-ui/icons'
import {estadoVerraco as estadoVerracoStilyzer} from '../comun/table/Stilyzers'
import {estadoAlbaran as estadoAlbaranConstants} from '../../constants/albaran'

class InformesPersonalizado extends Component {
  constructor (props) {
    super(props)
    this.state = {
      regillaSelccionada: 'verraco',
      metodoEvaluacion: '',
      idMotilidad: '',
      nameMotilidad: '',
      camposRegilla: [
        {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.ESTADO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'estadoverraco', stilyzer: estadoVerracoStilyzer},
        {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.CODIGO_ELIMINACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'code'},
        {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.CAUSA_ELIMINACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'causaEliminacion'},        
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.VERRACO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'codVerraco'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.TATUAJE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'tatuaje'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.VIGILADO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'vigilado'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.RAZA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'nombreRaza'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.LINEA_GENETICA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'nombreLinea'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_ENTRAD_CENTRO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaEntradaGranja', type: 'fecha'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_NACIMIENTO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaNacimiento', type: 'fecha'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_ELIMINACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaEliminado', type: 'fecha'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.EDAD'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'edad', type: 'numero', numDecimales: 0},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.INDEX'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'indexactual'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.PROPIEDAD'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'propiedad'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.UBICACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'ubicacionverraco'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.CROTAL'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'crotal'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_ENTRADA_CUARENTENA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaCuarentena', type: 'fecha'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_PRIMERA_EXTRACCION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fecha1Extraccion', type: 'fecha'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.SEMANAS_ESTANCIA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'semanasEstancia'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.GRANJA_ORIGENGRA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'granjaOrigen'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.PADRE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'padre'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.MADRE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'madre'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FIN_INTERVENCION_SANITARIA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaFinIntervencionSanitaria', type: 'fecha'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.TIPO_DOSIS_PREFERENTE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'tipoDosisPreferente'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.SALA_EXTRACCION_PREFERENTE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'VerracoSalaPreferente'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.TIEMPO_MONTA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'tiempoMonta'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.CONSERVACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'conservacion', stilyzer: (row) => {
            switch(row.conservacion) {
            case 1:
              return "*"
            case 2:
              return "**"
            case 3:
              return "***"
            case 4:
              return "****"
            case 5:
              return "*****"
            default:
              return '';
          }}},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.DESCRIPCION_VIGILADO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'descripcionVigilado'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.OBSERVACIONES'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'observacionesVerraco'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.GRASA_DORSAL'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'grasaDorsal'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.INDICE_CONVERSION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'indiceConversion'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.GMD'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'gmdVerraco'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.POR_MAGRO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'porMagro'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.GEN_HALOTANO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'genHalotano'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.IDENTIFICADOR'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'identificador'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.NUMERO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'numero'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.REGISTRO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'registro'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.EDAD_GENETICA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'edadGenetica'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.NUM_MACHOS_LOTE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'numMachosLote'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.ID_LOTE_ENTRADA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'idLoteEntrada'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.ID_GUIA_ENTRADA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'idGuiaEntrada'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.REGISTRO_LOTE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'registroLote'},
          {entidadSuperior: 'IndexVerraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.NIVEL_GENETICO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'nivelGenetico'},
          {entidadSuperior: 'IndexVerraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.INDEX'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'index'},
          {entidadSuperior: 'IndexVerraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaIndex', type: 'fecha'},
          {entidadSuperior: 'Alimentacion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.TIPO_ALIMENTACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'tipoAlimentacion'},
          {entidadSuperior: 'Alimentacion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_INICIO_ALIMENTACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaInicioAlimentacion', type: 'fecha'},
          {entidadSuperior: 'Alimentacion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.PRODUCTO_ALIMENTACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'producto'},
          {entidadSuperior: 'Alimentacion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.CANTIDAD'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'cantidad'},
          {entidadSuperior: 'Intervencion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.LOTE_ALIMENTACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'lote'},
          {entidadSuperior: 'Intervencion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.INTERVENCION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.H_VERRACO'), value: 'tipoIntervencion'},
          {entidadSuperior: 'Intervencion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_INICIO_INTERVENCION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.H_VERRACO'), value: 'fechaInicioIntervencion', type: 'fecha'},
          {entidadSuperior: 'Intervencion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.PRODUCTO_INTERVENCION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.H_VERRACO'), value: 'productoIntervencion'},
          {entidadSuperior: 'Intervencion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.LOTE_INTERVENCION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.H_VERRACO'), value: 'loteIntervencion'},
          {entidadSuperior: 'Intervencion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.OBSERVACIONES_INTERVENCION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.H_VERRACO'), value: 'observacionesIntervencion'}
      ],
      imputSelected: '',
      camposInformePersonalizado: []
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.INFORMES.INFORMES') + ' - ' + this.props.t('MENU.INFORMES.PERSONALIZADO') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.comboSeleccionesInformePersonalizado(this.state.regillaSelccionada)
    this.props.actions.cargarSeleccionInformePersonalizadoSuccess([])
    this.props.actions.mostrarSeleccionInformePersonalizadoSuccess([])
    if (Object.keys(this.props.panelControl).length > 0){
      this.props.actions.obtenerDatosPanelControl({value: this.props.panelControl.datosPanel.metodoEvaluacion})
      this.setState({metodoEvaluacion: this.props.panelControl.datosPanel.metodoEvaluacion})
      if (this.props.panelControl.datosPanel.metodoEvaluacion === 1) {
        this.setState({idMotilidad: 'motilidadTotal'})
        this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
      }
      if (this.props.panelControl.datosPanel.metodoEvaluacion === 2) {
        this.setState({idMotilidad: 'motilidadTotal'})
        this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
      }
      if (this.props.panelControl.datosPanel.metodoEvaluacion === 4) {
        this.setState({idMotilidad: 'motilidadMasal'})
        this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOT_MASAL')})
      }
      if (this.props.panelControl.datosPanel.metodoEvaluacion === 3) {
        this.setState({idMotilidad: 'mt'})
        this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
      }
      if (this.props.panelControl.datosPanel.metodoEvaluacion === 5) {
        this.setState({idMotilidad: 'mt'})
        this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
      }
      if (this.props.panelControl.datosPanel.metodoEvaluacion === 6) {
        this.setState({idMotilidad: 'mt'})
        this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
      }
    } else {
      this.props.actions.obtenerDatosPanelControl(null)
    }
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.panelControl) !== JSON.stringify(prevProps.panelControl)) {
      if (Object.keys(this.props.panelControl).length > 0){
        this.setState({metodoEvaluacion: this.props.panelControl.datosPanel.metodoEvaluacion})
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 1) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 2) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 4) {
          this.setState({idMotilidad: 'motilidadMasal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOT_MASAL')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 3) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 5) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 6) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
      }
    }
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosInformePersonalizado()
    } else {
      this.props.actions.deseleccionarTodosInformePersonalizado()
    }
  }

  mostrarSeleccion () {
    const itemsSeleccion = this.state.camposRegilla.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'nombre': row.nombre,
          'value': row.value,
          'idElemento': row.idElemento,
          'type': row.type,
          'numDecimales': row.numDecimales,
          'stilyzer': row.stilyzer
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    if (list.length > 0) {
      this.props.actions.mostrarSeleccionInformePersonalizado(list, this.state.regillaSelccionada)
    } else {
      this.props.actions.cargarSeleccionInformePersonalizadoSuccess([])
      this.props.actions.deseleccionarTodosInformePersonalizado()
      this.props.change('seleccionar-todo', false)
      this.props.actions.mostrarSeleccionInformePersonalizado([], this.state.regillaSelccionada)
    }
  }

  abrirModalGuardarSeleccion () {
    const itemsSeleccion = this.state.camposRegilla.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'nombre': row.nombre,
          'value': row.value,
          'idElemento': row.idElemento,
          'type': row.type,
          'numDecimales': row.numDecimales,
          'stilyzer': row.stilyzer
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    if (list.length > 0) {
      this.props.actions.abrirModalGuardarSeleccion(list, this.state.regillaSelccionada)
      this.props.change('seleccionar-todo', false)
      this.props.actions.deseleccionarTodosInformePersonalizado()
    }
  }

  cargarSeleccionInformePersonalizado (idInformePersonalizado) {
    let id
    if (idInformePersonalizado.nombre !== null) {
      id = idInformePersonalizado.nombre.value
    } else {
      id = null
    }
    this.props.actions.cargarSeleccionInformePersonalizado(id)
    this.props.actions.mostrarSeleccionInformePersonalizado([], this.state.regillaSelccionada)
  }

  eliminarSeleccionInformePersonalizado () {
    this.props.actions.eliminarSeleccionInformePersonalizado(this.props.datosSeleccionInformePersonalizado)
    this.props.actions.deseleccionarTodosInformePersonalizado()
    this.props.change('seleccionar-todo', false)
  }

  crearCsvInformePersonalizado () {
    const itemsSeleccion = this.state.camposRegilla.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'nombre': row.nombre,
          'value': row.value,
          'idElemento': row.idElemento,
          'type': row.type,
          'numDecimales': row.numDecimales,
          'stilyzer': row.stilyzer
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.crearCsvInformePersonalizado(list, this.state.regillaSelccionada)
  }
  imprimirPdfInformePersonalizado () {
    const itemsSeleccion = this.state.camposRegilla.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'nombre': row.nombre,
          'value': row.value,
          'idElemento': row.idElemento,
          'type': row.type,
          'numDecimales': row.numDecimales,
          'stilyzer': row.stilyzer
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.imprimirPdfInformePersonalizado(list, this.state.regillaSelccionada)
  }

  seleccionarRegilla (valor, e) {
    this.props.actions.comboSeleccionesInformePersonalizado(valor)
    this.props.actions.deseleccionarTodosInformePersonalizado()
    this.props.change('seleccionar-todo', false)
    this.props.change('nombre', '')
    this.props.actions.mostrarSeleccionInformePersonalizado([], this.state.regillaSelccionada)
    if (valor === 'verraco') {
      this.setState({camposRegilla:
        [
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.ESTADO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'estadoverraco', stilyzer: estadoVerracoStilyzer},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.CODIGO_ELIMINACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'code'},          
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.CAUSA_ELIMINACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'causaEliminacion'},          
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.VERRACO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'codVerraco'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.TATUAJE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'tatuaje'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.VIGILADO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'vigilado'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.RAZA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'nombreRaza'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.LINEA_GENETICA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'nombreLinea'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_ENTRAD_CENTRO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaEntradaGranja', type: 'fecha'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_NACIMIENTO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaNacimiento', type: 'fecha'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_ELIMINACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaEliminado', type: 'fecha'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.EDAD'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'edad', type: 'numero', numDecimales: 0},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.INDEX'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'indexactual'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.PROPIEDAD'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'propiedad'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.UBICACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'ubicacionverraco'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.CROTAL'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'crotal'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_ENTRADA_CUARENTENA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaCuarentena', type: 'fecha'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_PRIMERA_EXTRACCION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fecha1Extraccion', type: 'fecha'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.SEMANAS_ESTANCIA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'semanasEstancia'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.GRANJA_ORIGENGRA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'granjaOrigen'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.PADRE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'padre'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.MADRE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'madre'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FIN_INTERVENCION_SANITARIA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaFinIntervencionSanitaria', type: 'fecha'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.TIPO_DOSIS_PREFERENTE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'tipoDosisPreferente'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.SALA_EXTRACCION_PREFERENTE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'VerracoSalaPreferente'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.TIEMPO_MONTA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'tiempoMonta'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.CONSERVACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'conservacion', stilyzer: (row) => {
            switch(row.conservacion) {
            case 1:
              return "*"
            case 2:
              return "**"
            case 3:
              return "***"
            case 4:
              return "****"
            case 5:
              return "*****"
            default:
              return '';
          }}},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.DESCRIPCION_VIGILADO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'descripcionVigilado'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.OBSERVACIONES'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'observacionesVerraco'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.GRASA_DORSAL'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'grasaDorsal'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.INDICE_CONVERSION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'indiceConversion'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.GMD'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'gmdVerraco'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.POR_MAGRO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'porMagro'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.GEN_HALOTANO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'genHalotano'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.IDENTIFICADOR'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'identificador'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.NUMERO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'numero'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.REGISTRO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'registro'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.EDAD_GENETICA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'edadGenetica'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.NUM_MACHOS_LOTE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'numMachosLote'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.ID_LOTE_ENTRADA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'idLoteEntrada'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.ID_GUIA_ENTRADA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'idGuiaEntrada'},
          {entidadSuperior: 'Verraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.REGISTRO_LOTE'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'registroLote'},
          {entidadSuperior: 'IndexVerraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.NIVEL_GENETICO'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'nivelGenetico'},
          {entidadSuperior: 'IndexVerraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.INDEX'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'index'},
          {entidadSuperior: 'IndexVerraco', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaIndex', type: 'fecha'},
          {entidadSuperior: 'Alimentacion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.TIPO_ALIMENTACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'tipoAlimentacion'},
          {entidadSuperior: 'Alimentacion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_INICIO_ALIMENTACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'fechaInicioAlimentacion', type: 'fecha'},
          {entidadSuperior: 'Alimentacion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.PRODUCTO_ALIMENTACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'producto'},
          {entidadSuperior: 'Alimentacion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.CANTIDAD'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'cantidad'},
          {entidadSuperior: 'Intervencion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.LOTE_ALIMENTACION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.VERRACO'), value: 'lote'},
          {entidadSuperior: 'Intervencion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.INTERVENCION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.H_VERRACO'), value: 'tipoIntervencion'},
          {entidadSuperior: 'Intervencion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.FECHA_INICIO_INTERVENCION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.H_VERRACO'), value: 'fechaInicioIntervencion', type: 'fecha'},
          {entidadSuperior: 'Intervencion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.PRODUCTO_INTERVENCION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.H_VERRACO'), value: 'productoIntervencion'},
          {entidadSuperior: 'Intervencion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.LOTE_INTERVENCION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.H_VERRACO'), value: 'loteIntervencion'},
          {entidadSuperior: 'Intervencion', nombre: this.props.t('INFORMES.PERSONALIZADO.VERRACO.OBSERVACIONES_INTERVENCION'), entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.H_VERRACO'), value: 'observacionesIntervencion'}
        ]
      })
    }

    if (valor === 'extracciones') {
      if (this.state.metodoEvaluacion === 3 || this.state.metodoEvaluacion === 5) {
      this.setState({camposRegilla:
        [
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO_EXTRACCION'), value: 'codigoExtraccion', type: 'numero', numDecimales: 0},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.SEGUIMIENTO'), value: 'seguimiento'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VERRACO'), value: 'verracoExtraccion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.ESTADO'), value: 'estadoverraco', stilyzer: estadoVerracoStilyzer},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA'), value: 'fechaExtraccion', type: 'fecha'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.RAZA'), value: 'razaAnalisis'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.LINEA_GENETICA'), value: 'lineaGeneticaAnalisis'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.INDEX'), value: 'ebvExtraccion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DOSIS'), value: 'tipoDosisExtraccion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOLUMEN'), value: 'volumenExtraccion', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION_TOTAL'), value: 'concentracionTotal', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION_UTIL'), value: 'concentracionUtil', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MT'), value: 'mt' + 'Extraccion', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MP'), value: 'mp' + 'Extraccion', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MYN'), value: 'myn' + 'Extraccion', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_FORMAS_ANORMALES'), value: 'faTotalPercent', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TEMPERATURA_EYACULADO'), value: 'temperaturaEyaculado', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DILUYENTE'), value: 'nombreDiluyenteExtraccion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOL_DILUYENTE'), value: 'volumenDiluyenteExtraccion', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.NUM_DOSIS_REALES'), value: 'dosisReales', type: 'numero', numDecimales: 0},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VIGILADO'), value: 'verracoVigiladoExtraccion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.ORDEN'), value: 'ordenExtraccion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OPERARIO_COLECTA'), value: 'OperarioColectaAnalisis'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OPERARIO_PROCESADO'), value: 'OperarioProcesadoAnalisis'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.HORA_EXTRACCION'), value: 'horaExtraccion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.HORA_INICIO_PROCESADO'), value: 'horaInicioProcesado'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.HORA_FIN_PROCESADO'), value: 'horaFinProcesado'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.UBICACION'), value: 'nombreInstalacion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_ANALISIS'), value: 'TipoAnalisisExtraccion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CALIDAD'), value: 'calidadAnalisis'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.AGLUTINACION'), value: 'aglutinacionAnalisis'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_AGLUTINACION'), value: 'aglutinacionPercentAnalisis'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.ESPERMATOZOIDES_CONTADOS'), value: 'contadosExtraccion', type: 'numero', numDecimales: 0},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_ACROSOMAS'), value: 'porAcrosomas', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_CABEZAS'), value: 'porCA', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_COLAS'), value: 'porCO', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_COLAS_PLEGADAS'), value: 'porColasPlegadas', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_COLAS_OVILLO'), value: 'porColasOvillo', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_GOTA_PROXIMAL'), value: 'porGP', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_GOTA_DISTAL'), value: 'porGD', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_TOTAL'), value: 'porTotal', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION_X_DOSIS_APLICADAS'), value: 'concentracionxdosisaplicada', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION_X_DOSIS_UTIL'), value: 'concentracionxdosisutil', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.DOSIS_TEORICAS'), value: 'dosisTeoricas', type: 'numero', numDecimales: 0},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MEDIA_ULTIMAS_DOSIS'), value: 'mediaUltimasDosis', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.EYACULADO_ELIMINADO'), value: 'eyaculadoEliminado'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.RAZON_EYACULADO_ELIMINADO'), value: 'observacionesEyaculadoEliminado'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO_EYACULADO_ELIMINADO'), value: 'code'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OBSERVACIONES'), value: 'observaciones'},          
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('ANALISIS_EXTRACCIONES.FORM.FORM.OBSERVACIONES_FOSO'), value: 'observacionFoso'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO'), value: 'codigoSeguimiento'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA'), value: 'fechaSeguimiento', type: 'fecha'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_ANALISIS'), value: 'TipoAnalisisSeguimiento'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MT'), value: 'mt' + 'Seguimiento', type: 'numero'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MP'), value: 'mp' + 'Seguimiento', type: 'numero'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION'), value: 'concentracionSeguimiento', type: 'numero'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CALIDAD'), value: 'calidadSeguimiento'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.AGLUTINACION'), value: 'aglutinacionSeguimiento'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_FORMAS_ANORMALES'), value: 'formasAnormalesSeguimiento', type: 'numero'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONTAMINACION'), value: 'contaminacionSeguimiento', type: 'numero'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONSERVACION'), value: 'conservacionVerracoSeguimiento', type: 'numero'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_CONTROL'), value: 'tipoControlSeguimiento'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.EMPRESA'), value: 'empresaSeguimiento'},
          {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OBSERVACIONES'), value: 'observacionesSeguimiento'},
          {entidadSuperior: 'TrazabilidadProducto', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.PRODUCTO'), value: 'productoTrazabilidadAnalisis'},
          {entidadSuperior: 'TrazabilidadProducto', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.LOTE'), value: 'loteTrazabilidadAnalisis'},
          {entidadSuperior: 'TrazabilidadProducto', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONSUMIDO'), value: 'consumidoLoteAnalisis'},
          {entidadSuperior: 'TrazabilidadProducto', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA_CADUCIDAD'), value: 'fechaCaducidadLoteAnalisis', type: 'fecha'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.ESTADO'), value: 'estadoVerracoDilucion', stilyzer: estadoVerracoStilyzer},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VERRACO'), value: 'verracoDilucion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO_EXTRACCION'), value: 'codigoDilucion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA'), value: 'fechaDilucion', type: 'fecha'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOL_EYACULADO'), value: 'VolumenEyaculadoDilucion', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOL_DILUYENTE'), value: 'volumenDiluyenteDilucion', type: 'numero'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DILUYENTE'), value: 'diluyenteDilucion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DOSIS'), value: 'tipoDosisDilucion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.NUM_DE_DOSIS'), value: 'numeroDosisDilucion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OBSERVACIONES'), value: 'observacionesDilucion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MAQUINA'), value: 'maquinaDilucion'},
          {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OPERARIO'), value: 'operarioDilucion'},
          {entidadSuperior: 'TrazabilidadDilucion', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.PRODUCTO'), value: 'productoTrazabilidadDilucion'},
          {entidadSuperior: 'TrazabilidadDilucion', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.LOTE'), value: 'loteTrazabilidadDilucion'},
          {entidadSuperior: 'TrazabilidadDilucion', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONSUMIDO'), value: 'consumidoLoteDilucion'},
          {entidadSuperior: 'TrazabilidadDilucion', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA_CADUCIDAD'), value: 'fechaCaducidadLoteDilucion', type: 'fecha'},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.ESTADO'), value: 'estadoVerracoEnvasado', stilyzer: estadoVerracoStilyzer},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VERRACO'), value: 'verracoEnvasado'},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO_EXTRACCION'), value: 'codigoEnvasadoAnalisis'},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.COD_POOL'), value: 'codigoEnvasadoPool'},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA'), value: 'fechaEnvasado', type: 'fecha'},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.NUM_DE_DOSIS'), value: 'numeroDosisEnvasado'},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.RAZA'), value: 'RazaEnvasado'},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.LINEA_GENETICA'), value: 'LineaEnvasado'},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DOSIS'), value: 'TipoDosisEnvasado'},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA_CADUCIDAD'), value: 'fechaCaducidadEnvasado', type: 'fecha'},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TOTAL_DISPONIBLE'), value: 'totalDosisEnvasado', type: 'numero'},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TOTAL_UTILIZADO'), value: 'dosisUtilizadas', type: 'numero'},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOLUMEN'), value: 'volumenDosisEnvasado', type: 'numero'},
          {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MAQUINA'), value: 'maquinaEnvasado'},
          {entidadSuperior: 'TrazabilidadEnvasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.PRODUCTO'), value: 'productoTrazabilidadEnvasado'},
          {entidadSuperior: 'TrazabilidadEnvasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.LOTE'), value: 'loteTrazabilidadEnvasado'},
          {entidadSuperior: 'TrazabilidadEnvasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONSUMIDO'), value: 'consumidoLoteEnvasado'},
          {entidadSuperior: 'TrazabilidadEnvasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA_CADUCIDAD'), value: 'fechaCaducidadLoteEnvasado', type: 'fecha'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.NOMBRE'), value: 'nombrePool'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO'), value: 'codigoPool'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.SEGUIMIENTO'), value: 'seguimientoPool'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA'), value: 'fechaExtraccionPool', type: 'fecha'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.RAZA'), value: 'nombreRazaPool'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.LINEA_GENETICA'), value: 'nombreLineaPool'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DOSIS'), value: 'TipoDosisPool'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DILUYENTE'), value: 'nombreDiluyentePool'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOLUMEN'), value: 'volumenPool', type: 'numero'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_CABEZAS'), value: 'porcentajeFormasAnormalesCabezasPool', type: 'numero'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_COLAS'), value: 'porcentajeFormasAnormalesColasPool', type: 'numero'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_GOTA_PROXIMAL'), value: 'porcentajeFormasAnormalesGotaProximalPool', type: 'numero'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_GOTA_DISTAL'), value: 'porcentajeFormasAnormalesGotaDistalPool', type: 'numero'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_TOTAL'), value: 'porcentajeFormasAnormalesTotalPool', type: 'numero'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OPERARIO_PROCESADO'), value: 'operarioProcesadoPool'},
          {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO_EXTRACCION'), value: 'codigoExtraccionPool'},
          {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VERRACO'), value: 'verracoExtraccionPool'},
          {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOLUMEN'), value: 'volumenExtraccionPool'},
          {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOL_DILUYENTE'), value: 'volumenDiluyenteExtraccionPool'},
          {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MT'), value: this.state.idMotilidad + 'ExtraccionPool', type: 'numero'},
          {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MP'), value: 'mp' + 'ExtraccionPool', type: 'numero'},
          {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DOSIS'), value: 'dosisExtraccionPool'},
          {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.INDEX'), value: 'indexVerracoExtraccionPool'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION_X_DOSIS_APLICADAS'), value: 'concentracionXDosisAplicadaPool', type: 'numero'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION_X_DOSIS_UTIL'), value: 'concentracionXDosisUtilPool', type: 'numero'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.DOSIS_TEORICAS'), value: 'dosisTeoricasPool'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.NUM_DOSIS_REALES'), value: 'dosisRealesPool'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POOL_ELIMINADO'), value: 'poolEliminado'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OBSERVACIONES'), value: 'observacionesPool'},


          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO'), value: 'codigoPoolSeguimiento'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA'), value: 'fechaPoolSeguimiento'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_ANALISIS'), value: 'tipoAnalisisPoolSeguimiento', stilyzer: (row) => {
          switch(row.tipoAnalisisPoolSeguimiento) {
            case "1":
              return <span>{this.props.t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.COLORIMETRO')}</span>
            case "2":
              return <span>{this.props.t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.CAMARA_BURKER')}</span>
            case "3":
              return <span>{this.props.t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.MAGAVISION_I')}</span>
            case "4":
              return <span>{this.props.t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.MAGAVISION_M')}</span>
            case "5":
              return <span>{this.props.t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.NUEVO_MAGAVISION')}</span>
            default:
              return '';
          }}},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MT'), value: 'mt' + 'PoolSeguimiento'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MP'), value: 'mpPoolSeguimiento'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION'), value: 'concentracionPoolSeguimiento'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CALIDAD'), value: 'calidadPoolSeguimiento'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.AGLUTINACION'), value: 'aglutinacionPoolSeguimiento'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_FORMAS_ANORMALES'), value: 'formasAnormalesPoolSeguimiento'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONTAMINACION'), value: 'contaminacionPoolSeguimiento'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_CONTROL'), value: 'tipoControlPoolSeguimiento'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.EMPRESA'), value: 'empresaPoolSeguimiento'},
          {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OBSERVACIONES'), value: 'observacionesPoolSeguimiento'}
        ]
      })
      } else {
        this.setState({camposRegilla:
          [
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO_EXTRACCION'), value: 'codigoExtraccion', type: 'numero', numDecimales: 0},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.SEGUIMIENTO'), value: 'seguimiento'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VERRACO'), value: 'verracoExtraccion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.ESTADO'), value: 'estadoverraco', stilyzer: estadoVerracoStilyzer},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA'), value: 'fechaExtraccion', type: 'fecha'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.RAZA'), value: 'razaAnalisis'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.LINEA_GENETICA'), value: 'lineaGeneticaAnalisis'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.INDEX'), value: 'ebvExtraccion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DOSIS'), value: 'tipoDosisExtraccion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOLUMEN'), value: 'volumenExtraccion', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION_TOTAL'), value: 'concentracionTotal', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION_UTIL'), value: 'concentracionUtil', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MT'), value: 'mt' + 'Extraccion', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MYN'), value: 'myn' + 'Extraccion', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_FORMAS_ANORMALES'), value: 'faTotalPercent', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TEMPERATURA_EYACULADO'), value: 'temperaturaEyaculado', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DILUYENTE'), value: 'nombreDiluyenteExtraccion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOL_DILUYENTE'), value: 'volumenDiluyenteExtraccion', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.NUM_DOSIS_REALES'), value: 'dosisReales', type: 'numero', numDecimales: 0},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VIGILADO'), value: 'verracoVigiladoExtraccion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.ORDEN'), value: 'ordenExtraccion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OPERARIO_COLECTA'), value: 'OperarioColectaAnalisis'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OPERARIO_PROCESADO'), value: 'OperarioProcesadoAnalisis'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.HORA_EXTRACCION'), value: 'horaExtraccion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.HORA_INICIO_PROCESADO'), value: 'horaInicioProcesado'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.HORA_FIN_PROCESADO'), value: 'horaFinProcesado'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.UBICACION'), value: 'nombreInstalacion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_ANALISIS'), value: 'TipoAnalisisExtraccion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CALIDAD'), value: 'calidadAnalisis'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.AGLUTINACION'), value: 'aglutinacionAnalisis'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_AGLUTINACION'), value: 'aglutinacionPercentAnalisis'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.ESPERMATOZOIDES_CONTADOS'), value: 'contadosExtraccion', type: 'numero', numDecimales: 0},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_ACROSOMAS'), value: 'porAcrosomas', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_CABEZAS'), value: 'porCA', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_COLAS'), value: 'porCO', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_COLAS_PLEGADAS'), value: 'porColasPlegadas', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_COLAS_OVILLO'), value: 'porColasOvillo', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_GOTA_PROXIMAL'), value: 'porGP', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_GOTA_DISTAL'), value: 'porGD', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_TOTAL'), value: 'porTotal', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION_X_DOSIS_APLICADAS'), value: 'concentracionxdosisaplicada', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION_X_DOSIS_UTIL'), value: 'concentracionxdosisutil', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.DOSIS_TEORICAS'), value: 'dosisTeoricas', type: 'numero', numDecimales: 0},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MEDIA_ULTIMAS_DOSIS'), value: 'mediaUltimasDosis', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.EYACULADO_ELIMINADO'), value: 'eyaculadoEliminado'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.RAZON_EYACULADO_ELIMINADO'), value: 'observacionesEyaculadoEliminado'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO_EYACULADO_ELIMINADO'), value: 'code'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OBSERVACIONES'), value: 'observaciones'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES'), nombre: this.props.t('ANALISIS_EXTRACCIONES.FORM.FORM.OBSERVACIONES_FOSO'), value: 'observacionFoso'},
            {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO'), value: 'codigoSeguimiento'},
            {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA'), value: 'fechaSeguimiento', type: 'fecha'},
            {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_ANALISIS'), value: 'TipoAnalisisSeguimiento'},
            {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MT'), value: 'mt' + 'Seguimiento', type: 'numero'},
            {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION'), value: 'concentracionSeguimiento', type: 'numero'},
            {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CALIDAD'), value: 'calidadSeguimiento'},
            {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.AGLUTINACION'), value: 'aglutinacionSeguimiento'},
            {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_FORMAS_ANORMALES'), value: 'formasAnormalesSeguimiento', type: 'numero'},
            {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONTAMINACION'), value: 'contaminacionSeguimiento', type: 'numero'},
            {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONSERVACION'), value: 'conservacionVerracoSeguimiento', type: 'numero'},
            {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_CONTROL'), value: 'tipoControlSeguimiento'},
            {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.EMPRESA'), value: 'empresaSeguimiento'},
            {entidadSuperior: 'Seguimiento', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OBSERVACIONES'), value: 'observacionesSeguimiento'},
            {entidadSuperior: 'TrazabilidadProducto', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.PRODUCTO'), value: 'productoTrazabilidadAnalisis'},
            {entidadSuperior: 'TrazabilidadProducto', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.LOTE'), value: 'loteTrazabilidadAnalisis'},
            {entidadSuperior: 'TrazabilidadProducto', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONSUMIDO'), value: 'consumidoLoteAnalisis'},
            {entidadSuperior: 'TrazabilidadProducto', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA_CADUCIDAD'), value: 'fechaCaducidadLoteAnalisis', type: 'fecha'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.ESTADO'), value: 'estadoVerracoDilucion', stilyzer: estadoVerracoStilyzer},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VERRACO'), value: 'verracoDilucion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO_EXTRACCION'), value: 'codigoDilucion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA'), value: 'fechaDilucion', type: 'fecha'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOL_EYACULADO'), value: 'VolumenEyaculadoDilucion', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOL_DILUYENTE'), value: 'volumenDiluyenteDilucion', type: 'numero'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DILUYENTE'), value: 'diluyenteDilucion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DOSIS'), value: 'tipoDosisDilucion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.NUM_DE_DOSIS'), value: 'numeroDosisDilucion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OBSERVACIONES'), value: 'observacionesDilucion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MAQUINA'), value: 'maquinaDilucion'},
            {entidadSuperior: 'Extracciones', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OPERARIO'), value: 'operarioDilucion'},
            {entidadSuperior: 'TrazabilidadDilucion', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.PRODUCTO'), value: 'productoTrazabilidadDilucion'},
            {entidadSuperior: 'TrazabilidadDilucion', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.LOTE'), value: 'loteTrazabilidadDilucion'},
            {entidadSuperior: 'TrazabilidadDilucion', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONSUMIDO'), value: 'consumidoLoteDilucion'},
            {entidadSuperior: 'TrazabilidadDilucion', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.DILUCION_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA_CADUCIDAD'), value: 'fechaCaducidadLoteDilucion', type: 'fecha'},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.ESTADO'), value: 'estadoVerracoEnvasado', stilyzer: estadoVerracoStilyzer},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VERRACO'), value: 'verracoEnvasado'},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO_EXTRACCION'), value: 'codigoEnvasadoAnalisis'},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.COD_POOL'), value: 'codigoEnvasadoPool'},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA'), value: 'fechaEnvasado', type: 'fecha'},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.NUM_DE_DOSIS'), value: 'numeroDosisEnvasado'},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.RAZA'), value: 'RazaEnvasado'},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.LINEA_GENETICA'), value: 'LineaEnvasado'},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DOSIS'), value: 'TipoDosisEnvasado'},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA_CADUCIDAD'), value: 'fechaCaducidadEnvasado', type: 'fecha'},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TOTAL_DISPONIBLE'), value: 'totalDosisEnvasado', type: 'numero'},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TOTAL_UTILIZADO'), value: 'dosisUtilizadas', type: 'numero'},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOLUMEN'), value: 'volumenDosisEnvasado', type: 'numero'},
            {entidadSuperior: 'Envasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MAQUINA'), value: 'maquinaEnvasado'},
            {entidadSuperior: 'TrazabilidadEnvasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.PRODUCTO'), value: 'productoTrazabilidadEnvasado'},
            {entidadSuperior: 'TrazabilidadEnvasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.LOTE'), value: 'loteTrazabilidadEnvasado'},
            {entidadSuperior: 'TrazabilidadEnvasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONSUMIDO'), value: 'consumidoLoteEnvasado'},
            {entidadSuperior: 'TrazabilidadEnvasado', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ENVASADO_TRAZABILIDAD'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA_CADUCIDAD'), value: 'fechaCaducidadLoteEnvasado', type: 'fecha'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.NOMBRE'), value: 'nombrePool'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO'), value: 'codigoPool'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.SEGUIMIENTO'), value: 'seguimientoPool'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA'), value: 'fechaExtraccionPool', type: 'fecha'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.RAZA'), value: 'nombreRazaPool'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.LINEA_GENETICA'), value: 'nombreLineaPool'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DOSIS'), value: 'TipoDosisPool'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DILUYENTE'), value: 'nombreDiluyentePool'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOLUMEN'), value: 'volumenPool', type: 'numero'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OPERARIO_PROCESADO'), value: 'operarioProcesadoPool'},
            {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO_EXTRACCION'), value: 'codigoExtraccionPool'},
            {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VERRACO'), value: 'verracoExtraccionPool'},
            {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOLUMEN'), value: 'volumenExtraccionPool'},
            {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.VOL_DILUYENTE'), value: 'volumenDiluyenteExtraccionPool'},
            {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MT'), value: this.state.idMotilidad + 'ExtraccionPool', type: 'numero'},
            {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_DOSIS'), value: 'dosisExtraccionPool'},
            {entidadSuperior: 'pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.INDEX'), value: 'indexVerracoExtraccionPool'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_CABEZAS'), value: 'porcentajeFormasAnormalesCabezasPool', type: 'numero'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_COLAS'), value: 'porcentajeFormasAnormalesColasPool', type: 'numero'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_GOTA_PROXIMAL'), value: 'porcentajeFormasAnormalesGotaProximalPool', type: 'numero'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_GOTA_DISTAL'), value: 'porcentajeFormasAnormalesGotaDistalPool', type: 'numero'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_TOTAL'), value: 'porcentajeFormasAnormalesTotalPool', type: 'numero'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION_X_DOSIS_APLICADAS'), value: 'concentracionXDosisAplicadaPool', type: 'numero'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION_X_DOSIS_UTIL'), value: 'concentracionXDosisUtilPool', type: 'numero'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.DOSIS_TEORICAS'), value: 'dosisTeoricasPool'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.NUM_DOSIS_REALES'), value: 'dosisRealesPool'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POOL_ELIMINADO'), value: 'poolEliminado'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OBSERVACIONES'), value: 'observacionesPool'},


            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CODIGO'), value: 'codigoPoolSeguimiento'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.FECHA'), value: 'fechaPoolSeguimiento'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_ANALISIS'), value: 'tipoAnalisisPoolSeguimiento', stilyzer: (row) => {
            switch(row.tipoAnalisisPoolSeguimiento) {
              case "1":
                return <span>{this.props.t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.COLORIMETRO')}</span>
              case "2":
                return <span>{this.props.t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.CAMARA_BURKER')}</span>
              case "3":
                return <span>{this.props.t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.MAGAVISION_I')}</span>
              case "4":
                return <span>{this.props.t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.MAGAVISION_M')}</span>
              case "5":
                return <span>{this.props.t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.NUEVO_MAGAVISION')}</span>
              default:
                return '';
            }}},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.MT'), value: 'mt' + 'PoolSeguimiento'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONCENTRACION'), value: 'concentracionPoolSeguimiento'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CALIDAD'), value: 'calidadPoolSeguimiento'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.AGLUTINACION'), value: 'aglutinacionPoolSeguimiento'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.POR_FORMAS_ANORMALES'), value: 'formasAnormalesPoolSeguimiento'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.CONTAMINACION'), value: 'contaminacionPoolSeguimiento'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.TIPO_CONTROL'), value: 'tipoControlPoolSeguimiento'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.EMPRESA'), value: 'empresaPoolSeguimiento'},
            {entidadSuperior: 'Pool', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.POOL_SEGUIMIENTO'), nombre: this.props.t('INFORMES.PERSONALIZADO.EXTRACCIONES.OBSERVACIONES'), value: 'observacionesPoolSeguimiento'}
          ]
        })
      }
    }

    if (valor === 'planning') {
      this.setState({camposRegilla:
        [
          {entidadSuperior: 'Planning', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PLANNING'), nombre: this.props.t('INFORMES.PERSONALIZADO.PLANNING.VERRACO'), value: 'verracoPlanning'},
          {entidadSuperior: 'Planning', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PLANNING'), nombre: this.props.t('INFORMES.PERSONALIZADO.PLANNING.DIAS_DESDE_ULTIMA_EXTRACCION'), value: 'diasUltimaExtraccionPlanning'}
        ]
      })
    }

    if (valor === 'cliente') {
      this.setState({camposRegilla:
        [
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.CLIENTE'), value: 'razonSocialCliente'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.CIF'), value: 'cifCliente'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.DIRECCION'), value: 'direccion'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.LOCALIDAD'), value: 'localidad'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.PROVINCIA'), value: 'provincia'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.RUTA'), value: 'rutas'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.EMAIL'), value: 'email'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.TELEFONO'), value: 'telefono'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.TIPO_DIRECCION'), value: 'tipoDireccion', stilyzer: (row) => {
            switch(row.tipoDireccion) {
            case 2:
              return <span>{this.props.t('COMUN.COMBOS.TIPO_DIRECCION.ENVIO')}</span>
            case 3:
              return <span>{this.props.t('COMUN.COMBOS.TIPO_DIRECCION.CONTACTO')}</span>
            default:
              return '';
          }}},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.GRANJA'), value: 'nombreDireccion'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.DIRECCION'), value: 'direccionGranja'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.LOCALIDAD'), value: 'localidadGranja'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.PROVINCIA'), value: 'provinciaGranja'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.TRANSPORTISTA'), value: 'nombreTransportista'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.RUTA'), value: 'nombreRutaCliente'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.OBSERVACIONES'), value: 'observacionesCliente'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.NUM_PEDIDO'), value: 'numeroPedido'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.ESTADO'), value: 'estadoPedido', stilyzer: (row) => {
          switch(row.estadoPedido) {
            case 1:
              return <span className='estadopedido-borrador'>{this.props.t('COMUN.ESTADO_PEDIDO.BORRADOR')}</span>
            case 2:
              return <span className='estadopedido-cancelado'>{this.props.t('COMUN.ESTADO_PEDIDO.CANDELADO')}</span>
            case 3:
              return <span className='estadopedido-confirmado'>{this.props.t('COMUN.ESTADO_PEDIDO.CONFIRMADO')}</span>
            case 4:
              return <span className='estadopedido-realizado'>{this.props.t('COMUN.ESTADO_PEDIDO.REALIZADO')}</span>
            default:
              return '';
          }}},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.CLIENTE'), value: 'razonSocialClientePedido'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.GRANJA'), value: 'nombreDireccionPedido'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.FECHA'), value: 'fecha', type: 'fecha'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.NUM_DOSIS_PEDIDAS'), value: 'numeroDosisEnPedido'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.TIPO_DOSIS'), value: 'nombreDosisPedidoConcatenados'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.RAZA'), value: 'nombreRazaPedidoConcatenado'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.LINEA_GENETICA'), value: 'lineasGeneticasPedidoConcatenado'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.RUTA'), value: 'nombreRutaPedido'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.OBSERVACIONES'), value: 'observacionesPedido'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.OPERARIO'), value: 'nombreOperario'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.VERRACO'), value: 'codVerraco'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.RAZA'), value: 'nombreRazaPedido'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.LINEA_GENETICA'), value: 'nombreLineaPedido'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.TIPO_DOSIS'), value: 'nombreTipoDosis'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_PEDIDO'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.CANTIDAD'), value: 'numeroDosisLineaPedido'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.NUM_ALBARAN'), value: 'numeroAlbaran'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.ESTADO'), value: 'idEstado',stilyzer: (row) => {
            switch(row.idEstado) {
              case estadoAlbaranConstants["BORRADOR"]:
                return <span className='albaran-estado-borrador'>{this.props.t('COMUN.ESTADO_ALBARAN.BORRADOR')}</span>
              case estadoAlbaranConstants["CANCELADO"]:
                return <span className='albaran-estado-cancelado'>{this.props.t('COMUN.ESTADO_ALBARAN.CANDELADO')}</span>
              case estadoAlbaranConstants["REALIZADO"]:
                return <span className='albaran-estado-realizado'>{this.props.t('COMUN.ESTADO_ALBARAN.REALIZADO')}</span>
              default:
                return ''
            }
          }},

          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.CLIENTE'), value: 'razonSocialClienteAlbaran'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.GRANJA'), value: 'nombreDireccionAlbaran'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.FECHA_CREACION'), value: 'fechaCreacion', type: 'fecha'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.FECHA_REALIZADO'), value: 'fechaRealizacion', type: 'fecha'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.TIPO_DOSIS'), value: 'nombreDosisAlbaranConcatenados'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.TRANSPORTISTA'), value: 'nombreTransportistaAlbaran'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.RUTA'), value: 'nombreRuta'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.CONDUCTOR'), value: 'nombreConductor'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.VEHICULO'), value: 'modeloVehiculo'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.OPERARIO'), value: 'nombreOperarioAlbaran'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.NUM_DE_DOSIS'), value: 'numeroDosis'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.TIPO_DOSIS'), value: 'nombreTipoDosis'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.OBSERVACIONES'), value: 'observaciones'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.RAZA'), value: 'nombreRaza'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.TOTAL_DOSIS'), value: 'numeroDosisEnPedido'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.OBSERVACIONES_CLIENTE'), value: 'observacionesClienteAlbaran'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.RAZA'), value: 'nombreRazaAlbaran'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.LINEA_GENETICA'), value: 'nombreLineaAlbaran'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.TIPO_DOSIS'), value: 'nombreTipoDosisAlbaran'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.DOSIS_PEDIDAS'), value: 'dosisPedidas'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.DOSIS_ENVIADAS'), value: 'dosisEnviadas'},
          
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.DOSIS_FALTAN_POR_SERVIR'), value: 'faltanPorServir'},

          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.VERRACO_VERRACO_POOL'), value: 'verracoPoolVerraco'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.VERRACO_POOL'), value: 'verracoPool'},
          {entidadSuperior: 'Cliente', entidad: this.props.t('INFORMES.PERSONALIZADO.REGILLA.LINEA_ALBARAN'), nombre: this.props.t('INFORMES.PERSONALIZADO.CLIENTE.EXTRACCION_POOL'), value: 'extraccionPool'},
        ]
      })
    }

    this.setState({regillaSelccionada: valor})
    var elems = document.getElementById('regilla-informe-pers').getElementsByTagName('span');
    [].forEach.call(elems, function(el) {
       el.classList.remove("regilla-activa")
    })

    document.getElementsByClassName(e.target.className)[0].classList.add("regilla-activa")
  }

  render () {
    const {
      t, handleSubmit, numeroRegistrosPorPagina, paginaActual, checkedInformePersonalizado, datosTablaPersonalizado, valoresTablaPersonalizado, comboSeleccionesInformePersonalizado,
      datosSeleccionInformePersonalizado
    } = this.props

    this.state.camposRegilla.forEach(
      (row, idRow) => row.idElemento = row.value
    )
    const tableRegilla = {
      id: 'regillaTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      mostrarModificar: false,
      hasPagination: false,
      noBlockOptions: false,
      informePersonalizado: true,
      paginarConScroll: true,
      columns: [
        {id: 'nombre', name: t('INFORMES.PERSONALIZADO.REGILLA.CAMPOS')},
        {id: 'entidad', name: t('INFORMES.PERSONALIZADO.REGILLA.ENTIDAD')}
      ],
      rows: this.state.camposRegilla,
      initialValues: {},
    }
    tableRegilla.rows.forEach(
      (row, idRow) => {
        // if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableRegilla.initialValues['check' + row.idElemento] = checkedInformePersonalizado
        // }
      }
    )

    // tabla personalizado
    const tablePersonalizado = {
      id: 'personalizadoTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      hasPagination: true,
      noBlockOptions: false,
      columns: valoresTablaPersonalizado && Object.keys(valoresTablaPersonalizado).length > 0 ? valoresTablaPersonalizado : [],
      rows: datosTablaPersonalizado && Object.keys(datosTablaPersonalizado).length > 0 ? datosTablaPersonalizado : [],
      initialValues: {},
    }


    return (      
      <div>
        <h2 className="page-title">{t('INFORMES.PERSONALIZADO.TITLE')}</h2>
        <ModalGuardarSeleccionInformePersonalizadoPage />
        <SimpleModalPage />
        <MensajeAlertaPage />
        <Row>
          <Col id="regilla-informe-pers" sm={12} className="regilla-informe-pers">
            <span className="1-regilla regilla-activa" onClick={(e) => this.seleccionarRegilla('verraco', e)}>{t('INFORMES.PERSONALIZADO.REGILLA.VERRACO')}</span>
            <span className="2-regilla" onClick={(e) => this.seleccionarRegilla('extracciones', e)}>{t('INFORMES.PERSONALIZADO.REGILLA.EXTRACCIONES')}</span>
            <span className="4-regilla" onClick={(e) => this.seleccionarRegilla('planning', e)}>{t('INFORMES.PERSONALIZADO.REGILLA.PLANNING')}</span>
            <span className="5-regilla" onClick={(e) => this.seleccionarRegilla('cliente', e)}>{t('INFORMES.PERSONALIZADO.REGILLA.CLIENTES')}</span>
          </Col>
          <div className="clearfix"></div>
          <Col sm={12} className="barra-filtros-inf-pers">
            <div className="buttons-flex">
              <Col sm={12} className="acciones-tabla-filtros">
                <div className="buttons-flex">
                  <div className="button-group buttons-min" style={{display: 'flex', width: '100%'}}>
                    <div className="custom-check-w" key="checkbox_key">
                      <Field
                        id="seleccionar-todo"
                        name="seleccionar-todo"
                        colSm={2}
                        inline
                        component={InputCheckBox}
                        onInputChange={(value) => this.intercambiarCheck(value)}
                      />
                    </div>
                    <button type="button" className="btn btn-primary nuevo mostrar-seleccion-personalizado" onClick={() => this.mostrarSeleccion()}>
                      <RemoveRedEye /> {t('INFORMES.PERSONALIZADO.REGILLA.MOSTRAR_SELECCION')}
                    </button>
                    <Field
                      colSm={2}
                      id="nombre"
                      name="nombre"
                      // controlLabel={t('INFORMES.CLIENTES.FILTROS.PROVINCIA')}
                      component={InputSelect}
                      options={comboSeleccionesInformePersonalizado}
                      valueKey="value"
                      labelKey="label"
                      customClass='selector-informe-personalizado'
                      onInputChange={handleSubmit(this.cargarSeleccionInformePersonalizado.bind(this))}
                    />
                    {
                      Object.keys(datosSeleccionInformePersonalizado).length > 0 ? (
                        <button type="button" className="btn btn-icono mostrar-seleccion-personalizado" onClick={() => this.eliminarSeleccionInformePersonalizado()}><Delete /></button>
                      ) : null
                    }
                    <button type="button" className="btn btn-primary nuevo mostrar-seleccion-personalizado" onClick={() => this.abrirModalGuardarSeleccion()}>
                      <Save /> {t('INFORMES.PERSONALIZADO.REGILLA.GUARDAR_SELECCION')}
                    </button>
                    {
                      Object.keys(valoresTablaPersonalizado).length > 0 ? (
                        <div>
                          <button type="button" className="btn btn-icono mostrar-seleccion-personalizado" onClick={(e) => this.crearCsvInformePersonalizado()}>
                            <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                          </button>
                          <button type="button" className="btn btn-icono mostrar-seleccion-personalizado" onClick={() => this.imprimirPdfInformePersonalizado()}>
                            <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                          </button>
                        </div>
                      ) : null
                    }
                  </div>
                </div>
              </Col>
            </div>
          </Col>
          <Col sm={4} className="tabla-regilla-informe-pers">
            <SimpleTablePage {...tableRegilla}>
            </SimpleTablePage>
          </Col>
          <Col sm={8} className="tabla-regilla-informe-pers">
            <SimpleTablePage {...tablePersonalizado}>
            </SimpleTablePage>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'InformesPersonalizado',
})(InformesPersonalizado))