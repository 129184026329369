import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { yesNoModal,resetReloadComponent } from '../../actions/common'
import {openModalListadoSeguimiento} from '../../actions/seguimiento/seguimiento'
import {
  fetchPoolExtracciones, recuperarDatosFormPoolExtraccion, fetchCodigoPoolExtracciones, handlePoolCalcFunctionsOrden, submitFormPoolExtraccion,
  initializeFormPoolExtraccion, initializeFormPoolExtraccionSuccess, onDeleteAsignacionAnalisisPool, descargarEtiquetaPdf, imprimirPantalla, imprimirEtiquetaPDFPool,
  imprimirMiniEtiquetaPool, calcVolumenDiluyentePool
} from '../../actions/poolExtracciones/poolExtracciones'

import FormPoolExtraccion from '../../components/poolExtracciones/FormPoolExtraccion'

export function mapStateToProps (state) {
  return {
    auth: {...state.auth},
    combos: {
      comboRaza: state.combos.comboRaza,
      comboOperarioPredeterminado: state.combos.comboOperarioPredeterminado,
      comboTipoDosis: state.combos.comboTipoDosis,
    },
    isLoading: state.common.isLoading,
    panelControl: state.panelControl,
    pool: state.poolExtracciones.pool,
    filtros: state.poolExtracciones.filtros,
    list: state.poolExtracciones.list,
    showModalFormSeguimiento: state.seguimiento.modalFormSeguimiento.showModalFormSeguimiento,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    asignacion_view: state.poolExtracciones.asignacion_view,
    reloadComponent: {
      ...state.common.reloadComponent
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      yesNoModal,
      fetchPoolExtracciones,
      recuperarDatosFormPoolExtraccion,
      fetchCodigoPoolExtracciones,
      handlePoolCalcFunctionsOrden,
      submitFormPoolExtraccion,
      initializeFormPoolExtraccion,
      initializeFormPoolExtraccionSuccess,
      onDeleteAsignacionAnalisisPool,
      openModalListadoSeguimiento,
      descargarEtiquetaPdf,
      imprimirPantalla,
      imprimirEtiquetaPDFPool,
      imprimirMiniEtiquetaPool,
      resetReloadComponent,
      calcVolumenDiluyentePool
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FormPoolExtraccion))