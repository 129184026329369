import { fork, all } from 'redux-saga/effects'
import { watchFetchToken, watchLogin, watchLogout, watchRequestResetPassword, watchResetPassword, watchTestAuth, watchSubmitRenovarToken, watchComprobarEntorno, watchComprobarEntornoLocal, watchObtenerNumeroSerie, watchSubmitActualizarAplicacion, watchMostrarInformacionLicencia } from './auth/auth'
import { watchFetchRazas } from './raza/raza'
import { watchFetchLineaGenetica, watchSubmitNuevaLineaGenetica, watchRecuperarDatosModal, watchAbrirModalVacio, watchEditarLineaGenetica, watchDuplicarDatosModal, 
watchCambiarEstado, watchOnDelete, watchFetchFiltrarLinea, watchCambiarEstadoSeleccion, watchDeleteSeleccion, watchCrearCsv, watchGuardarYnuevo, watchImprimirPdfLinea } from './lineaGenetica/lineaGenetica'
import { watchYesNoModal } from './modal/yesNoModal'
import { watchSubmitNuevaRaza, watchRecuperarDatosModalRaza, watchAbrirModalVacioRaza, watchEditarRaza, watchDuplicarDatosModalRaza, watchCambiarEstadoRaza, watchOnDeleteRaza,
watchFetchFiltrarRaza, watchCambiarEstadoSeleccionRaza, watchDeleteSeleccionRaza, watchCrearCsvRaza, watchGuardarYnuevoRaza, watchImprimirPdfRaza} from './raza/raza'

import { watchfetchGrupoVerraco, watchSubmitNuevoGrupoVerraco, watchRecuperarDatosModalGrupoVerraco, watchAbrirModalVacioGrupoVerraco, watchEditarGrupoVerraco, watchDuplicarDatosModalGrupoVerraco,
    watchCambiarEstadoGrupoVerraco, watchOnDeleteGrupoVerraco, watchFetchFiltrarGrupoVerraco, watchCambiarEstadoSeleccionGrupoVerraco, watchDeleteSeleccionGrupoVerraco, watchCrearCsvGrupoVerraco,
    watchGuardarYnuevoGrupoVerraco, watchFetchVerracosGrupoVerraco, watchAnadirVerraco, watchOnDeleteVerracoAnadido, watchDeleteSeleccionVerracosGrupoVerraco, watchFetchFiltrarVerracoModalVerraco,
    watchImprimirPdfGrupoVerraco} from './grupoVerraco/grupoVerraco'

import {
  watchComboCodLineaGenetica, watchComboLineaGenetica, watchComboCodRaza, watchComboRaza, watchComboNombreGrupoVerraco,
  watchComboVerraco, watchComboTipoInstalacionPadre, watchComboTipoInstalacion, watchComboInstalacionPadre, watchComboCodInstalacion,
  watchComboNombreInstalacion, watchComboOperario, watchComboSalaExtraccion, watchComboCliente, watchComboDireccionCliente, watchComboDireccionClienteSecundaria, watchComboVerracoSinUbicacion,
  watchComboInstalacionesPadre, watchComboUbicacion, watchComboTipoDosis, watchComboObtenerCentroUsuario, watchComboListadoVerracos, watchComboObservacionesEyaculadoEliminado, watchComboCausaEliminacion,
  watchComboTipoAlimentacion, watchComboSalaExtraccionActivo, watchFetchComboTipoDiluyente, watchComboTipoIntervencionSanitaria,
  watchComboMaquina, watchComboRuta, watchComboCifCliente, watchComboLocalidadCliente, watchComboNombresOperarios, watchComboApellidosOperarios,
  watchComboLocalidadOperarios, watchComboEmpresaVisitas, watchComboContactoVistas, watchComboLineaGeneticaActiva, watchComboRazaActiva,
  watchComboOperarioActivo, watchFetchComboRectaCalibrado, watchComboEmpresaTransportista, watchComboConductoresTransportista,
  watchComboVehiculosTransportista, watchFetchComboPool, watchComboUsuariosCentro, watchComboGrupoUsuariosCentro, watchComboNombreProductos, watchComboReferenciaProductos, watchComboNombreEscandallo,
  watchComboProductoEscandallo, watchComboProveedoresEntradaMercancia, watchComboAlbaranesEntradaMercancia, watchComboOrigenesEntradaMercancia, watchComboLotes, watchComboProductosActivos, watchComboProductosActivosTrazabilidad,
  watchComboLotesProductoEspecificoActivo, watchComboLotesProductoEspecificoActivoTrazabilidad, watchComboRazonSocialClientesActivos, watchComboAliasClientesActivos, watchComboLotesActivos, watchComboIndex, watchComboUbicacionPadre,
  watchComboVerracosPresentesActivos, watchComboVerracosExtraccionAlbaran, watchComboPoolsExtraccionAlbaran, watchComboClienteActivo, watchComboClienteSecundarioActivo, watchComboClienteConVerracosEnPropiedad, watchComboNumeroPedido,
  watchComboTransportistasCliente, watchComboRutaTransportistaCliente, watchComboVehiculosTransportistaDeterminado, watchComboInstalacionPadreActiva, watchComboFiltroVerracosLimpieza,
  watchComboConductoresTransportistaDeterminado, watchComboNumerosAlbaran, watchComboEmpresaTransportistaActivos, watchComboLocalidadClientes, watchComboProvinciasClientes,
  watchComboUsuariosNoAsignadosOperario, watchComboOperarioPredeterminado, watchComboProductosValidacionActivos, watchComboNombreGrupoVerracoActiva, watchComboTodasUbicaciones, watchComboRutasActivas, watchComboInstalacionesPadreOSinHijos,
  watchComboVerracoConSeguimiento, watchComboDireccionClienteSinPredeterminado, watchComboDireccionClienteActivo, watchComboRazaGranja, watchComboLineaGeneticaGranja, watchComboTipoDosisGranja, watchComboNivelGenetico, watchComboNivelGeneticoGeneral, 
  watchComboNivelGeneticoActivo, watchComboNivelGeneticoActivoGranja, watchComboCodPool} from './combos/combos'

import { watchFetchPanelControl, watchObtenerDatosPanelControl, watchGuardarPanelControl, watchObtenerValoresTeclasContaje, watchValoresPredefinidosTeclasContaje, watchGuardarTeclasContaje,
   watchAbrirModalTeclasContaje, watchAnadirDiluyentePanelControl, watchAbrirModalTipoDiluyente, watchRecuperarDatosTipoDiluyente, watchEditarDiluyentePanelControl, watchDuplicarDatosModalTipoDiluyente,
   watchCambiaPredeterminadoDiluyente, watchOnDeleteDiluyente, watchDeleteSeleccionTipoDiluyente, watchAnadirDosisPanelControl, watchAbrirModalTipoDosis, watchRecuperarDatosTipoDosis,
   watchEditarDosisPanelControl, watchDuplicarDatosModalTipoDosis, watchCambiaPredeterminadoDosis, watchOnDeleteDosis,watchDeleteSeleccionTipoDosis, watchSubmitAnadirColorimetro,
   watchFetchRectaColorimetro, watchRecuperarDatosRectaColorimetro, watchEditarColorimetro, watchDuplicarDatosModalRectaColorimetro, watchCambiaPredeterminadoRectaColorimetro,
   watchOnDeleteRectaColorimetro, watchAbrirModalAnadirMuestras, watchRecuperarModalMuestras, watchEditMuestrasAnadidas, watchDuplicarModalMuestras, watchOnDeleteMuestras, watchDeleteSeleccionRectaColorimetro,
   watchFetchListadoVerracos, watchAnadirVerracoRecta, watchEditarVerracoRecta, watchValoresPredefinidosPanelControl, watchOnDeleteVerracos, watchAnadirMuestra, watchRecuperarSecuenciaNumeraciones,
   watchFetchSecuenciasNumeracion, watchEditarSecuencia, watchResetPedido, watchAbrirConfiguracionMagavision} from './panelControl/panelControl'

import { watchFetchOperario, watchSubmitNuevaOperario, watchRecuperarDatosModalOperario, watchAbrirModalVacioOperario, watchEditarOperario, watchDuplicarDatosModalOperario,
    watchCambiarEstadoOperario, watchOnDeleteOperario, watchFetchFiltrarOperario, watchCambiarEstadoSeleccionOperario, watchDeleteSeleccionOperario, watchCrearCsvOperario,
    watchGuardarYnuevoOperario, watchCambiarOperarioProcesado, watchCambiarOperarioAlbaran, watchImprimirPdfOperario, watchImprimirEtiquetaOperario } from './operario/operario'

import { watchFetchNivelGenetico, watchSubmitNuevaNivelGenetico, watchRecuperarDatosModalNivelGenetico, watchAbrirModalVacioNivelGenetico, watchEditarNivelGenetico, watchOnDeleteNivelGenetico} from './nivelGenetico/nivelGenetico' 

import { watchFetchMicrobiologia, watchSubmitNuevaMicrobiologia, watchRecuperarDatosModalMicrobiologia, watchAbrirModalVacioMicrobiologia, watchEditarMicrobiologia, watchOnDeleteMicrobiologia, watchFetchFiltrarMicrobiologia, watchCrearCsvMicrobiologia} from './planSanitario/microbiologia/microbiologia'  

import {watchFetchTipoInstalacion, watchSubmitNuevaTipoInstalacion, watchRecuperarDatosModalTipoInstalacion, watchAbrirModalVacioTipoInstalacion, watchEditarTipoInstalacion,
    watchDuplicarDatosModalTipoInstalacion, watchCambiarEstadoTipoInstalacion, watchOnDeleteTipoInstalacion, watchFetchFiltrarTipoInstalacion, watchCambiarEstadoSeleccionTipoInstalacion,
    watchDeleteSeleccionTipoInstalacion, watchGuardarYnuevoTipoInstalacion, watchCrearCsvTipoInstalacion, watchImprimirPdfTipoInstalacion} from './tipoInstalacion/tipoInstalacion'
import {watchFetchInstalacion, watchSubmitNuevaInstalacion, watchRecuperarDatosModalInstalacion, watchAbrirModalVacioInstalacion, watchEditarInstalacion, watchDuplicarDatosModalInstalacion,
    watchCambiarEstadoInstalacion, watchOnDeleteInstalacion, watchFetchFiltrarInstalacion, watchCambiarEstadoSeleccionInstalacion, watchDeleteSeleccionInstalacion, watchCrearCsvInstalacion,
    watchGuardarYnuevoInstalacion, watchImprimirPdfInstalacion} from './instalacion/instalacion'
import { watchFetchFichaVerraco, watchSubmitNuevoVerraco, watchAsignarCodRaza, watchAbrirModalVacioEvolucion, watchRecuperarDatosModalEvolucion, watchFetchDuplicarVerraco,
    watchEditarEvolucion, watchDeleteEvolucion, watchDuplicarDatosModalEvolucion, watchDeleteSeleccionEvolucion, watchAbrirModalVacioAlimentacion,
watchGetAlimentacion, watchSubmitNuevaAlimentacion, watchRecuperarDatosModalAlimentacion, watchEditarAlimentacion, watchDeleteAlimentacion, watchDuplicarDatosModalAlimentacion,
watchDeleteSeleccionAlimentacion, watchGuardarYnuevoAlimentacion, watchAbrirModalVacioIndex, watchGetIndex, watchRecuperarDatosModalIndex, watchEditarIndex,
watchDeleteIndex, watchDuplicarDatosModalIndex, watchDeleteSeleccionIndex, watchCambiarVigilado, watchOnDeleteVerraco, watchCambiarVigiladoSeleccionFichaVerraco,
watchDeleteSeleccionFichaVerraco, watchCerrarModalCambioEstadoVerraco, watchGuardarYnuevoVerraco, watchFetchFiltrarFichaVerraco, watchObtenerPresenciaVerraco, watchCrearCsvFichaVerraco, watchImprimirPdfFichaVerraco, watchImprimirDetalleVerraco} from './fichaVerraco/fichaVerraco'
import { watchCambiarCentro, watchObtenerArchivoContent } from './comun/comun'
import { watchFetchEditarVerraco, watchSubmitEditVerraco, watchFetchHistoricoEstadosVerraco, watchFetchHistoricoGrupoVerraco, watchFetchHistoricoExtracciones, watchFetchHistoricoIntervenciones, watchAbrirModalHistoricoGrupos , watchAbrirModalHistoricoExtracciones, watchAbrirModalHistoricoEstado, watchAbrirModalHistoricoIntervenciones} from './fichaVerraco/editarVerraco/editarVerraco'
import { watchSubmitNuevoMoviVerraco, watchUbicacionenesVacias, watchEnviarFechaMovimientoVerraco, watchFetchMovimientoVerraco, watchRecuperarDatosModalMovimientoVerraco, watchAbrirModalVacioUbicacion,
    watchEditarMoviVerraco, watchDuplicarDatosModalMovimientoVerraco, watchOnDeleteMovimientoVerraco, watchDeleteSeleccionMovimientoVerraco, watchFetchFiltrarMovimientoVerraco,
    watchGuardarYNuevoMovimiento, watchCrearCsvMovimientoVerraco, watchImprimirPdfMovimientoVerraco} from './movimientoVerraco/movimientoVerraco'
import { watchEnviarFechaCambioEstado, watchsubmitNuevoCambioEstadoVerraco, watchFetchCambioEstadoVerraco, watchAbrirModalVacioCambioEstado, watchRecuperarDatosModalCambioEstadoVerraco,
    watchEditarCambioEstadoVerraco, watchDuplicarDatosModalCambioEstadoVerraco, watchOnDeleteCambioEstadoVerraco, watchDeleteSeleccionCambioEstadoVerraco, watchFetchFiltrarCambioEstadoVerraco,
    watchCrearCsvCambioEstado, watchImprimirPdfCambioEstado} from './cambioEstadoVerraco/cambioEstadoVerraco'
import { watchFetchTipoAlimentacion, watchAbrirModalVacioTipoAlimentacion, watchSubmitNuevoTipoAlimentacion, watchRecuperarDatosModalTipoAlimentacion, watchEditarTipoAlimentacion,
    watchDuplicarDatosModalTipoAlimentacion, watchCambiarEstadoTipoAlimentacion, watchOnDeleteTipoAlimentacion, watchCambiarEstadoSeleccionTipoAlimentacion, watchDeleteSeleccionTipoAlimentacion,
    watchGuardarYnuevoTipoAlimentacion, watchCrearCsvTipoAlimentacion, watchImprimirPdfTipoAlimentacion} from './planSanitario/tipoAlimentacion/tipoAlimentacion'
import { watchFetchVisita, watchAbrirModalVacioVisita, watchSubmitNuevoVisita, watchRecuperarDatosModalVisita, watchEditarVisita, watchDuplicarDatosModalVisita, watchOnDeleteVisita,
    watchDeleteSeleccionVisita, watchGuardarYnuevoVisita, watchFetchFiltrarVisita, watchImprimirInformeVisita, watchCrearCsvVisita, watchImprimirPdfVisita } from './planSanitario/visita/visita'
import { watchFetchRegistroAlimentacion, watchAbrirModalVacioRegistroAlimentacion, watchSubmitNuevoRegistroAlimentacion, watchRecuperarDatosModalRegistroAlimentacion, watchEditarRegistroAlimentacion,
    watchDuplicarDatosModalRegistroAlimentacion, watchOnDeleteRegistroAlimentacion, watchDeleteSeleccionRegistroAlimentacion, watchGuardarYnuevoRegistroAlimentacion, watchFetchFiltrarRegistroAlimentacion, 
    watchFetchVerracosRegistroAlimentacion, watchAnadirVerracoModalRegistroAlimentacion, watchFetchFiltrarVerracoModalRegistroAlimentacion, watchOnDeleteVerracoAnadidoRegistroAlimentacion,
    watchDeleteSeleccionVerracosRegistroAlimentacion, watchCrearCsvRegistroAlimentacion, watchImprimirPdfRegistroAlimentacion, watchImprimirInformeRegistroAlimentacion} from './planSanitario/registroAlimentacion/registroAlimentacion'
import {watchFetchTipoIntervencionSanitaria, watchAbrirModalVacioTipoIntervencionSanitaria, watchSubmitNuevoTipoIntervencionSanitaria, watchRecuperarDatosModalTipoIntervencionSanitaria,
    watchEditarTipoIntervencionSanitaria, watchDuplicarDatosModalTipoIntervencionSanitaria,  watchCambiarEstadoTipoIntervencionSanitaria, watchOnDeleteTipoIntervencionSanitaria,
    watchCambiarEstadoSeleccionTipoIntervencionSanitaria, watchDeleteSeleccionTipoIntervencionSanitaria, watchGuardarYnuevoTipoIntervencionSanitaria, watchCrearCsvTipoIntervencion, 
    watchImprimirPdfTipoIntervencion } from './planSanitario/tipoIntervencionSanitaria/tipoIntervencionSanitaria'
import {
    watchFetchRegistroIntervencionSanitaria,
    watchAbrirModalVacioRegistroIntervencionSanitaria,
    watchSubmitNuevoRegistroIntervencionSanitaria,
    watchRecuperarDatosModalRegistroIntervencionSanitaria,
    watchEditarRegistroIntervencionSanitaria,
    watchDuplicarDatosModalRegistroIntervencionSanitaria,
    watchOnDeleteRegistroIntervencionSanitaria,
    watchDeleteSeleccionRegistroIntervencionSanitaria,
    watchGuardarYnuevoRegistroIntervencionSanitaria,
    watchFetchFiltrarRegistroIntervencionSanitaria,
    watchFetchVerracosRegistroIntervencionSanitaria,
    watchAnadirVerracoModalRegistroIntervencionSanitaria,
    watchFetchFiltrarVerracoModalRegistroIntervencionSanitaria,
    watchOnDeleteVerracoAnadidoRegistroIntervencionSanitaria,
    watchDeleteSeleccionVerracosRegistroIntervencionSanitaria,
    watchCrearCsvRegistroIntervencion,
    watchImprimirPdfRegistroIntervencion,
    watchAbrirModalAgendaIntervencionSanitaria,
    watchGuardarModalAgendaIntervencionSanitaria,
    watchCerrarModalAgendaIntervencionSanitaria,
    watchImprimirInformeRegistroIntervencion,
    watchObtenerArchivoContentIntervencion
    } from './planSanitario/registroIntervencionSanitaria/registroIntervencionSanitaria'

import {
  watchFetchAnalisisExtracciones, watchCambiarEyaculadoEliminadoAnalisisExtracciones, watchCambiarVigiladoAnalisisExtracciones, watchOnDeleteAnalisisExtraccion,
  watchCambiarEyaculadoEliminadoSeleccionAnalisisExtracciones, watchCambiarVigiladoSeleccionAnalisisExtracciones, watchDeleteSeleccionAnalisisExtracciones,
  watchCrearCsvAnalisisExtracciones, watchImprimirPdfAnalisisExtracciones, watchCalcularDiasDescanso, watchSincronizarPendientesDataSwine,
  watchSubmitFormAnalisisExtraccion, watchRecuperarDatosFormAnalisisExtraccion, watchInitializeFormAnalisisExtraccion,
  watchFetchCodigoAnalisisExtracciones, watchUpdateComboVerraco, watchFetchTokenMagavision,
  watchFetchDatosVerracoExtracciones, watchChangeTipoAnalisisExtraccion, watchAbrirMagavision, watchCerrarNuevoMagavision, watchVerInformeMagavision,
  watchObtenerYGuardarResultadoSistemaAnalisis, watchGuardarResultadoSistemaAnalisis,
  watchHandleAnalisisCalcFunctionsOrden, watchCalcConcentracionTotal, watchCalcConcentracionUtil, watchCalcConcentracionXDosis,
  watchCalcNumeroDosis, watchReCalcConcentracionXDosis, watchCalcVolumenDiluyente, watchAbrirModalDilucionAnalisisExtraccion, watchAbrirModalPoolAnalisisExtraccion,
  watchGuardarModalDilucionAnalisisExtraccion, watchCerrarModalDilucionAnalisisExtraccion, watchPrepararMaquinaDilucionAnalisisExtraccion, watchPdfEtiquetaExtraccion,
  watchImprimirEtiquetaPDF, watchAbrirBascula, watchGuardarModalPoolAnalisisExtraccion, watchImprimirMiniEtiquetaAnalisis, watchLecturaRFIDAnalisis, watchShowUltimasExtraccionesVerraco
} from './analisisExtracciones/analisisExtracciones'

import {
  watchFetchPoolExtracciones, watchOnDeletePoolExtraccion, watchDeleteSeleccionPoolExtracciones,
  watchCrearCsvPoolExtracciones, watchImprimirPdfPoolExtracciones,
  watchSubmitFormPoolExtraccion, watchRecuperarDatosFormPoolExtraccion,
  watchInitializeFormPoolExtraccion, watchFetchCodigoPoolExtracciones, watchFetchTokenMagavisionPool,
  watchHandlePoolCalcFunctionsOrden, watchCalcConcentracionXDosisPool, watchCalcNumeroDosisPool,
  watchReCalcConcentracionXDosisPool, watchOnDeleteAsignacionAnalisisPool, watchDeleteSeleccionAsignacionAnalisisPool,
  watchAbrirModalAsignacionAnalisisPool, watchCerrarModalAsignacionAnalisisPool,
  watchGuardarModalAsignacionAnalisisPool, watchFetchAnalisisDisponibles, watchDescargarEtiquetaPdf, watchImprimirEtiquetaPDFPool, watchImprimirMiniEtiquetaPool,
  watchCrearExcelBatchUSA,
  watchImprimirPantalla, watchCalcVolumenDiluyentePool
} from './poolExtracciones/poolExtracciones'
import {
  watchAddAnalisisPool, watchRemoveAnalisisPool, watchInitializeExtraccionesPendientesAsignar,
  watchCreateNewPool, watchFetchExtraccionesPendientesDeAsignar, watchAbrirModalNuevoPoolAsignacion,
  watchRecuperarDatosPool, watchObtenerNumeroPool
} from './asignacionPoolVerraco/asignacionPoolVerraco'
import { watchAbrirModalAsignacionDosis, watchAddDosisAPedido, watchFetchLineasAlbaranes, watchDeleteSeleccionDosisEnvasadas } from './asignacionPedidos/asignacionPedidos'
import {
  watchOpenModalContajeMaquinas, watchCloseModalContajeMaquinas
} from './analisisExtracciones/contajeMaquinas'

import {
  watchComprobarCamara, watchGetImagenCamara, /*watchGetVideoCamara,*/ watchApagarCamara
} from './analisisExtracciones/camaraView'

import { watchAbrirModalVacioBioseguridadHigiene, watchFetchBioseguridadHigiene, watchFetchLimpiezaSilos, watchAbrirModalLimpiezaSilos, watchOnDeleteSiloAnadido, watchDeleteSeleccionModalSilos,
  watchFetchLimpiezaCorral, watchAbrirModalLimpiezaCorral, watchOnDeleteCorralAnadido, watchDeleteSeleccionModalCorral, watchFetchUbicacion, watchAbrirModalUbicacion, watchOnDeleteUbicacionAnadido,
  watchDeleteSeleccionModalUbicacion, watchFetchVerraco, watchAbrirModalVerraco, watchOnDeleteVerracoAnadidoBioseguridad, watchDeleteSeleccionModalVerraco, watchFetchGrupoVerraco,
  watchAbrirModalGrupoVerraco, watchOnDeleteGrupoVerracoAnadidoBioseguridad, watchDeleteSeleccionModalGrupoVerraco, watchSubmitAnaliticaAgua, watchSubmitLimpiezaSilos, watchSubmitLimpiezaCorrales,
  watchSubmitOtros, watchRecuperarDatosModalBioseguridadHigiene, watchModificarAnaliticaAgua, watchModificarLimpiezaSilos, watchModificarLimpiezaCorral, watchModificarOtros, watchDuplicarDatosModalBioseguridadHigiene,
  watchOnDeleteBioseguridadHigiene, watchGuardarNuevoAnaliticaAgua, watchGuardarNuevoLimpiezaSilos, watchGuardarNuevoLimpiezaCorrales, watchGuardarNuevoOtros, watchDeleteSeleccionBioseguridadHigiene,
  watchFetchFiltrarBioseguridadHigiene, watchCrearCsvBioseguridad, watchImprimirPdfBioseguridad, watchAbrirModalAgendaBioseguridad, watchGuardarModalAgendaBioseguridad, watchCerrarModalAgendaBioseguridad, watchObtenerArchivoContentBioseguridad, watchImprimirInformeBioseguridad} from './planSanitario/bioseguridadHigiene/bioseguridadHigiene'

import {
  watchFetchDilucion, watchAbrirDetalles, watchEditarDilucion, watchPrepararMaquinaDilucion, watchCerrarDetalles, watchFetchFiltrarDilucion, watchCrearCsvDilucion, watchImprimirPdfDilucion, watchDeleteSeleccionDilucion, watchOnDeleteDilucion
} from './dilucion/dilucion'

import {watchfetchRuta, watchAbrirModalVacioRuta, watchOnDeleteClienteAnadido, watchSubmitNuevoRuta, watchFetchClienteRuta, watchDeleteSeleccionClientesRuta, watchRecuperarDatosModalRuta,
  watchEditarRuta, watchDuplicarDatosModalRuta, watchGuardarYnuevoRuta, watchCambiarEstadoRuta, watchOnDeleteRuta, watchDeleteSeleccionRuta, watchCambioEstadoSeleccionRuta,
  watchFetchFiltrarRuta, watchFetchFiltrarClienteRuta, watchCrearCsvRuta, watchImprimirPdfRuta} from './ruta/ruta'

import {watchAbrirModalConductor, watchRecuperarModalCondutor, watchEditConductorAnadido, watchCambiarEstadoConductor, watchOnDeleteConductorAnadido, watchFetchTransportista,
  watchFiltrarTrasporte, watchDuplicarConductor, watchConductorPorDefecto, watchAbrirModalVehiculo, watchRecuperarModalVehiculo, watchEditVehiculoAnadido, watchCambiarEstadoVehiculo,
  watchOnDeleteVehiculoAnadido, watchDuplicarVehiculo, watchVehiculoPorDefecto, watchFiltrarRutasTransporte, watchAbrirModalRuta, watchOnDeleteRutaAnadida, watchRutaPorDefecto, watchSubmitNuevoTransportista,
  watchGuardarYnuevoTransporte, watchFetchEditarTransportista, watchSubmitEditarTransportista, watchCambiarEstadoTransporte, watchOnDeleteTransporte, watchCambioEstadoSeleccionTransporte,
  watchDeleteSeleccionTransporte, watchCrearCsvTransporte, watchImprimirPdfTransporte} from './transporte/transporte'

import {watchSubmitNuevoProducto, watchFiltrarProducto, watchRecuperarDatosModalProducto, watchAbrirProducto, watchEditarProducto, watchDuplicarDatosModalProducto, watchCambiarEstadoProducto,
  watchOnDeleteProducto, watchCambiarEstadoSeleccionProducto, watchDeleteSeleccionProducto, watchCrearCsvProducto, watchImprimirPdfProducto} from './producto/producto'

import { watchFiltrarEscandallo, watchAbrirEscandallo, watchSubmitNuevoEscandallo, watchRecuperarDatosModalEscandalloAnadido, watchEditarEscandalloAnadido, watchOnDeleteEscandalloAnadido,
  watchDuplicarDatosModalEscandalloAnadido, watchRecuperarDatosModalEscandallo, watchDuplicarDatosModalEscandallo, watchEditarEscandallo, watchGuardarYnuevoEscandallo, watchCambiarEstadoEscandallo,
  watchOnDeleteEscandallo, watchCambiarEstadoSeleccionEscandallo, watchDeleteSeleccionEscandallo, watchCrearCsvEscandallo, watchImprimirPdfEscandallo} from './escandallo/escandallo'

import { watchComprobarStockTrazabilidad, watchUpdateStockInsuficiente, watchObtenerLotesEscandalloAnalisis, watchObtenerLotesEscandalloEnvasado, watchUpdateLotesEscandallo, watchOpenModalTrazabilidadMaterial,
  watchGuardarModalTrazabilidadMaterial, watchCloseModalTrazabilidadMaterial, watchDeleteTrazabilidadMaterial, watchDeleteSeleccionTrazabilidadMaterial,
  watchOpenModalEditarTrazabilidadMaterial, watchGuardarModalEditarTrazabilidadMaterial, watchCloseModalEditarTrazabilidadMaterial, watchActualizarTrazabilidadMaterial} from './trazabilidadMaterial/trazabilidadMaterial'

import { watchFetchMaterialDisponible, watchOpenModalMaterialDisponible, watchGuardarModalMaterialDisponible, watchCloseModalMaterialDisponible } from './trazabilidadMaterial/materialDisponible'

import { watchFiltrarEntradaMercancia, watchRecuperarModalEntradaMercanciaProductoAnadido, watchEditarEntradaMercanciaProducto, watchAbrirModalEntradaMercanciaAnadirProducto,
  watchDuplicarDatosModalEntradaMercanciaAnadido, watchOnDeleteEntradaMercanciaProducto, watchGuardarYnuevoEntradaMercanciaProducto, watchSubmitNuevoEntradaMercancia, watchRecuperarDatosModalEntradaMercancia,
  watchEditarEntradaMercancia, watchAbrirModalEntradaMercancia, watchDuplicarDatosModalEntradaMercancia, watchGuardarYnuevoEntradaMercancia, watchOnDeleteEntradaMercancia, watchDeleteSeleccionEntradaMercancia, watchDeleteSeleccionEntradaMercanciaProductoAnadido,
  watchCrearCsvEntradaMercancia, watchImprimirPdfEntradaMercancia} from './entradaMercancia/entradaMercancia'

import {watchFiltrarValidacionMateriaPrima, watchAbrirValidacionMateriaPrima, watchObtenerVerracosMateriaPrima, watchObtenerPoolMateriaPrima, watchRecuperarDatosModalVerracoPool, watchEditarVerracoPool,
  watchDuplicarDatosModalVerracoPool, watchOnDeleteVerracoPool, watchSubmitNuevoValidacionMateriaPrima, watchRecuperarDatosModalValidacionMateriaPrima, watchEditarValidacionMateriaPrima, watchDuplicarDatosModalValidacionMateriaPrima, watchOnDeleteValidacionMateriaPrima, watchDeleteSeleccionValidacionMateriaPrima, watchCrearCsvValidacionMateriaPrima, watchEditarVerracoMateriaPrima, watchEditarPoolMateriaPrima,
watchImprimirPdfValidacionMateriaPrima, watchGuardarYnuevoValidacionMateriaPrima} from './validacionMateriaPrima/validacionMateriaPrima'

import {
  watchFetchAjusteStock, watchOpenModalAjusteStock, watchCloseModalAjusteStock, watchGuardarAjusteStock, watchCrearCsvAjusteStock, watchImprimirPdfAjusteStock
} from './ajusteStock/ajusteStock'

import {
  watchFetchEnvasado, watchFetchFiltrarEnvasado, watchCrearCsvEnvasado, watchImprimirPdfEnvasado,
  watchAbrirDetallesEnvasado, watchCerrarDetallesEnvasado, watchEditarEnvasado, watchDeleteEnvasadoMultiDosis, watchDeleteSeleccionEnvasadoMultiDosis,
  watchAbrirModalEnvasadoMultiDosis, watchAbrirModalEnvasadoMultiDosisDesdeListadoPrincipal, watchGuardarModalEnvasadoMultiDosis,
  watchPrepararMaquinaEnvasado, watchCerrarModalEnvasadoMultiDosis, watchUpdateTotalUtilizadoDetalleEnvasado, watchlecturaRFIDEnvasado, watchDeleteSeleccionDosis
} from './envasado/envasado'

import {
  watchFetchSeguimientos, watchOpenModalListadoSeguimiento, watchCloseModalListadoSeguimiento, watchDeleteSeguimiento,
  watchDeleteSeleccionSeguimiento, watchCrearCsvSeguimiento, watchImprimirPdfSeguimiento, watchOpenModalFormSeguimiento,
  watchGuardarModalFormSeguimiento, watchCloseModalFormSeguimiento, watchObtenerYGuardarResultadoSistemaAnalisisSeguimiento,
  watchFetchFiltrarModalNuevoSeguimiento, watchObtenerYGuardarResultadoSistemaAnalisisMultiSeguimiento
} from './seguimiento/seguimiento'

import {watchFiltrarCliente, watchRecuperarDatosModalCliente, watchSubmitNuevoCliente, watchEditarCliente, watchDuplicarDatosModalCliente, watchCambiarEstadoCliente, watchGuardarYnuevoCliente,
  watchOnDeleteCliente, watchCambiarEstadoSeleccionCliente, watchDeleteSeleccionCliente, watchCrearCsvCliente, watchImprimirPdfCliente, watchAbrirCliente, watchRecuperarDatosModalDireccionCliente,
  watchEditarDireccionCliente, watchDuplicarDatosModalDireccionCliente, watchOnDeleteDireccionCliente, watchOnDeleteRutaCliente, watchOnDeleteDatosArticuloDosis, watchComboRutasNoDireccionCliente, watchAnadirDatosRuta, watchDatosArticuloDosis} from './cliente/cliente'

import {watchFetchFiltrarPlaning, watchAnadirFechaPlaning, watchEliminarFechaPlaning, watchAsignarVerrracos, watchAnadirPlaning, watchCargarVerracosPlanificados, watchOnDeleteVerracoPlaning,
  watchObtenerVerracosSinExtraer, watchImprimirModalInicioPlaning, watchAbrirModalPlaning, watchDeleteSeleccionVerracoPlaning, watchExportarModalInicioPlaning, watchTotalesPlaning,
  watchExportarPlaning, watchImprimirPlaning, watchImprimirZebraVerracos, watchExportarSugerenciaExtracciones, watchImprimirSugerenciaExtracciones, watchAnadirSeleccionPlaning, watchEnviarFechaDuplicarPlaning, watchSubmitDuplicarPlaning, watchAnadirSeleccionPlaningPrincipal, watchEliminarSeleccionPlaningPrincipal, watchTotalesPlaningAlbaran, watchObtenerPrevisionVerracos} from './planing/planing'

import {watchFetchAlbaranes, watchDuplicarDatosModalAlbaran, watchOnDeleteAlbaran, watchDeleteSeleccionAlbaranes, watchCrearCsvAlbaranes, watchImprimirPdfAlbaranes, watchAbrirModalVacioAlbaran, watchAbrirDetallesAlbaran, watchCerrarDetallesAlbaran,
  watchImprimirAlbaran,watchImprimirDetalleAlbaran, watchEditarAlbaran, watchAbrirModalVacioAlbaranDosis, watchAbrirDetallesAlbaranDosis, watchCerrarDetallesAlbaranDosis,
  watchEditarAlbaranDosis, watchDeleteModalAlbaranDosis, watchDeleteSeleccionModalAlbaranDosis, watchCalcTotalesAlbaranDosis,
  watchEditarAlbaranEImprimirDatosAlbaran, watchImprimirDatosAlbaran, watchImprimirDatosAlbaranCliente, watchImprimirEtiquetaPDFAlbaran, watchLeerEtiquetasRFID
  } from './albaran/albaran'

import {watchRecuperarDatosModalDatosDosis, watchEditarDatosModalDatosDosis, watchDuplicarDatosModalDatosDosis, watchOnDeleteDatosDosis, watchAnadirPedidosVenta, watchAnadirPrevisionPedidos, watchFetchFiltrarPedidosVenta,
  watchElegirClientePedidoVenta, watchElegirClienteSecundarioPedidoVenta, watchRecuperarDatosModalPedidosVenta, watchEditarPedidosVenta, watchDuplicarDatosModalPedidosVenta, watchOnDeletePedidosVenta, watchDeleteSeleccionPedidosVenta, watchConfirmarSeleccionPedidosVenta,
  watchExportarPedidosVenta, watchImprimirPedidosVenta, watchVerracosPedido, watchCrearAlbaranDesdePedido, watchAbrirDetallesPedido, watchDuplicarPedidoVenta, watchObtenerNumeroPedido,
  watchElegirDireccionClientePedidoVenta, watchConfirmarYnuevoPedidosVenta, watchDescargarPedidosCloud} from './pedidosVenta/pedidosVenta'

import { watchFetchFiltrarFoso, watchObtenerDatosVerraco, watchObtenerExtraccion, watchGuardarFoso, watchModificarFoso, watchEliminarFoso, watchExportarFoso, watchImprimirFoso, watchImprimirEtiquetaZebra
} from './foso/foso'

import {watchFetchNuevaConectividad, watchFetchFiltrarConectividad, watchRecuperarDatosModalConectividad, watchEditarConectividad, watchDuplicarDatosModalConectividad,
  watchCambiarEstadoConectividad, watchOnDeleteConectividad, watchCambiarEstadoSeleccionConectividad, watchDeleteSeleccionConectividad, watchCrearCsvConectividad,
  watchImprimirPdfConectividad, watchGuardarYnuevoConectividad, watchActualizarConectividadVpn, watchObternebternerConectividadVpn, watchActualizarConectividadVpnVacio,
  watchProbarConexionVpn} from './conectividad/conectividad'

import {watchFetchAgenda, watchFetchTareasDia, watchAbrirModalAgenda, watchGuardarModalAgenda, watchCloseModalAgenda, watchOnDeleteAgenda, watchOnDeleteAgendaSerie} from './agenda/agenda'

import {watchObtenerInformeExtracciones, watchObtenerInformeStockDosis, watchObtenerInformePool, watchObtenerInformeSeguimientoExtraccionesPool, watchObtenerInformeProductividadExtracciones,
  watchObtenerInformeProductividadCentro, watchObtenerInformeTrazabilidad, watchObtenerInformeIntervenciones, watchObtenerInformeCliente, watchObtenerRegistrosSeguimientosExtraccionesPool,
  watchObtenerVerracosProductividadExtracciones, watchObtenerRecomendacionesMatadero, watchObtenerInformeEstadoCentro, watchImprimirPdfInformesExtracciones, watchImprimirPdfInformesPool, watchImprimirPdfInformesProductividadExtracciones, watchImprimirPdfInformesSeguimientoExtraccionesPool, watchImprimirPdfInformesProductividadCentro, watchImprimirPdfInformesTrazabilidad, watchImprimirPdfInformesIntervenciones, watchImprimirPdfInformesClientes, watchImprimirPdfInformesStockDosis, watchCrearCsvInformeExtracciones, watchCrearCsvInformePool, watchCrearCsvInformeProductividadExtracciones, watchImprimirPdfInformesProductividadExtraccionesVerraco, watchCrearCsvInformeProductividadExtraccionesVerraco, watchImprimirPdfInformesProductividadExtraccionesMatadero, watchCrearCsvInformeProductividadExtraccionesMatadero, watchCrearCsvInformeStockDosis, watchCrearCsvInformeClientes, watchCrearCsvInformeTrazabilidad, watchCrearCsvInformeIntervenciones, watchCrearCsvInformeProductividadCentro, watchImprimirPdfInformesEstadoCentro, watchCrearCsvInformesEstadoCentro, watchExportarInformeSeguimientoExtraccionesPool, watchMostrarSeleccionInformePersonalizado, watchAbrirModalGuardarSeleccion, watchSubmitGuardarSeleccionInformePersonalizado, watchComboSeleccionesInformePersonalizado, watchCargarSeleccionInformePersonalizado, watchEliminarSeleccionInformePersonalizado, watchImprimirPdfInformePersonalizado, watchCrearCsvInformePersonalizado} from './informes/informes'

import {watchFetchEmpresa, watchEditarEmpresa, watchSubmitNuevoCentro, watchRecuperarDatosCentro, watchEditarCentro, watchDuplicarCentro, watchCambiarEstadoCentro,
  watchEliminarCentro, watchDeleteSeleccionCentros, watchCambioEstadoSeleccionCentros, watchCrearCsvEmpresa, watchImprimirPdfEmpresa} from './empresa/empresa'

import {watchFetchUsuarios, watchFetchCentrosUsuarios, watchFetchUsuarioGrupoUsuarios, watchSubmitNuevoUsuario, watchRecuperarDatosUsuario, watchEditarUsuarios, watchDuplicarUsuario,
  watchCambiarEstadoUsuario, watchEliminarUsuario, watchDeleteSeleccionUsuario, watchCambioEstadoSeleccionUsuario, watchCrearCsvUsuarios, watchImprimirPdfUsuarios,
  watchCambiarPasswordUsuario, watchCambiarTokenMagavision, watchSubmitCambiarTokenMagavision} from './usuarios/usuarios'

import {watchFetchGrupoUsuarios, watchAbrirModalGrupoUsuarios, watchDuplicarGrupoUsuarios, watchSubmitGrupoUsuarios, watchCerrarModalGrupoUsuarios,
  watchEliminarGrupoUsuarios, watchCambiarEstadoGrupoUsuarios, watchDeleteSeleccionGrupoUsuarios, watchCambioEstadoSeleccionGrupoUsuarios,
  watchCrearCsvGrupoUsuarios, watchImprimirPdfGrupoUsuarios} from './grupoUsuarios/grupoUsuarios'

import { watchEditarPerfil, watchFetchPerfil, watchCambiarPasswordPerfil } from './perfil/perfil'

import { watchFetchPlano } from './plano/plano'

import { watchFetchCopiasSeguridad, watchSubmitNuevaCopiaSeguridad, watchDescargarCopiaSeguridad, watchOnDeleteCopiaSeguridad, watchDeleteSeleccionCopiaSeguridad, watchRestaurarCopiaSeguridad,
  watchObtenerProgramacionCopiaSeguridad, watchObtenerProgramacionCopiaSeguridadProgramada, watchSubmitCopiaSeguridadDesdeArchivo, watchSubmitSubmitRestaurarMigracion } from './copiaSeguridad/copiaSeguridad'

import { watchFetchLicencias, watchAbrirModalLicencias, watchSubmitLicencia, watchCerrarModalLicencias, watchOnDeleteLicencia } from './admin/licencias/licencias'

import { watchFetchDashboard, watchFetchDashboardProduccion } from './dashboard/dashboard'

import { watchSubmitCargaIndex, watchDescargarPlantillaCsv } from './cargaIndex/cargaIndex'

import { watchSubmitImportarDatos } from './importarDatos/importarDatos'

import { watchSubmitImportarVerracos, watchDescargarPlantillaExcelVerracos, watchDescargarVerracos } from './importarVerracos/importarVerracos'

import { watchSubmitImportarPedidos, watchDescargarPlantillaExcelPedidos } from './importarPedidos/importarPedidos'

import {watchObtenerNotificaconesUsuario, watchMarcarComoLeidoNotificacion, watchMarcarComoLeidoNotificacionStock} from './notificaciones/notificaciones'

import {
  watchFetchControlMorfologico, watchOpenModalListadoControlMorfologico, watchCloseModalListadoControlMorfologico, watchDeleteControlMorfologico,
  watchDeleteSeleccionControlMorfologico, watchCrearCsvControlMorfologico, watchImprimirPdfControlMorfologico, watchOpenModalFormControlMorfologico,
  watchGuardarModalFormControlMorfologico, watchCloseModalFormControlMorfologico, watchObtenerYGuardarResultadoSistemaAnalisisControlMorfologico,
  watchFetchFiltrarModalInicio, watchAnadirVerracosModalInicio, watchGuardarResultadoSistemaAnalisisControlMorfologico
} from './planSanitario/controlCalidad/controlMorfologico'


import {
  watchFetchControlSangre, watchAnadirVerracosModalInicioControlSangre, watchFetchFiltrarModalInicioControlSangre,
  watchDeleteControlSangre, watchCrearCsvControlSangre, watchImprimirPdfControlSangre, watchDescargarArchivoControlSangre,
  watchDeleteSeleccionControlSangre, watchimprimirEtiquetaControlSangre, watchRecuperarDatosModalControlSangre, watchguardarDetalleControlSangre
  /*, watchOpenModalListadoControlSangre, watchCloseModalListadoControlMorfologico */
} from './planSanitario/controlSangre/controlSangre'

import {
  watchFetchControlSemen, watchAnadirVerracosModalInicioControlSemen, watchFetchFiltrarModalInicioControlSemen,
  watchDeleteControlSemen, watchDeleteSeleccionControlSemen, watchCrearCsvControlSemen, watchImprimirPdfControlSemen, watchDescargarArchivoControlSemen,
  watchimprimirEtiquetaControlSemen, watchRecuperarDatosModalControlSemen, watchguardarDetalleControlSemen
  /*, watchOpenModalListadoControlSangre, watchCloseModalListadoControlMorfologico */
} from './planSanitario/controlSemen/controlSemen'

import {
  watchFetchComboCausaEliminacion,watchSubmitNuevaComboCausaEliminacion,watchRecuperarDatosModalComboCausaEliminacion,watchAbrirModalVacioComboCausaEliminacion,
  watchEditarComboCausaEliminacion
} from './comboCausaEliminacion/comboCausaEliminacion'

import {
  watchFetchComboObservacionesEyaculadoEliminado,watchSubmitNuevaComboObservacionesEyaculadoEliminado,watchRecuperarDatosModalComboObservacionesEyaculadoEliminado,watchAbrirModalVacioComboObservacionesEyaculadoEliminado,
  watchEditarComboObservacionesEyaculadoEliminado
} from './comboObservacionesEyaculadoEliminado/comboObservacionesEyaculadoEliminado'

import {
  watchFetchAnalisisParaPIC, watchFetchPICTraits, watchInsertAnalisisPIC, watchSaveDataPIC, 
  watchGetUrlPICSaga, watchSincronizarIndexPICSaga, watchfetchPoolParaPIC,
  watchInsertPoolPIC, watchSaveDataPoolPIC,
  watchInsertSplitsPIC, watchSaveDataSplitsPIC
} from './PICTraq/PICTraq'


export default function * root () {
  yield all([
    fork(watchFetchToken),
    fork(watchLogin),
    fork(watchLogout),
    fork(watchRequestResetPassword),
    fork(watchResetPassword),
    fork(watchTestAuth),
    fork(watchSubmitRenovarToken),
    fork(watchComprobarEntorno),
    fork(watchComprobarEntornoLocal),
    fork(watchObtenerNumeroSerie),
    fork(watchSubmitActualizarAplicacion),
    fork(watchMostrarInformacionLicencia),
    fork(watchFetchRazas),
    fork(watchFetchLineaGenetica),
    fork(watchSubmitNuevaLineaGenetica),
    fork(watchRecuperarDatosModal),
    fork(watchAbrirModalVacio),
    fork(watchEditarLineaGenetica),
    fork(watchDuplicarDatosModal),
    fork(watchCambiarEstado),
    fork(watchYesNoModal),
    fork(watchOnDelete),
    fork(watchComboCodLineaGenetica),
    fork(watchComboLineaGenetica),
    fork(watchFetchFiltrarLinea),
    fork(watchCambiarEstadoSeleccion),
    fork(watchDeleteSeleccion),
    fork(watchCrearCsv),
    fork(watchGuardarYnuevo),
    fork(watchSubmitNuevaRaza),
    fork(watchRecuperarDatosModalRaza),
    fork(watchEditarRaza),
    fork(watchAbrirModalVacioRaza),
    fork(watchDuplicarDatosModalRaza),
    fork(watchCambiarEstadoRaza),
    fork(watchOnDeleteRaza),
    fork(watchFetchFiltrarRaza),
    fork(watchCambiarEstadoSeleccionRaza),
    fork(watchDeleteSeleccionRaza),
    fork(watchCrearCsvRaza),
    fork(watchGuardarYnuevoRaza),
    fork(watchComboCodRaza),
    fork(watchComboRaza),
    fork(watchfetchGrupoVerraco),
    fork(watchComboNombreGrupoVerraco),
    fork(watchComboVerraco),
    fork(watchFetchPanelControl),
    fork(watchSubmitNuevoGrupoVerraco),
    fork(watchRecuperarDatosModalGrupoVerraco),
    fork(watchAbrirModalVacioGrupoVerraco),
    fork(watchEditarGrupoVerraco),
    fork(watchDuplicarDatosModalGrupoVerraco),
    fork(watchCambiarEstadoGrupoVerraco),
    fork(watchOnDeleteGrupoVerraco),
    fork(watchFetchFiltrarGrupoVerraco),
    fork(watchCambiarEstadoSeleccionGrupoVerraco),
    fork(watchDeleteSeleccionGrupoVerraco),
    fork(watchCrearCsvGrupoVerraco),
    fork(watchGuardarYnuevoGrupoVerraco),
    fork(watchFetchOperario),
    fork(watchSubmitNuevaOperario),
    fork(watchRecuperarDatosModalOperario),
    fork(watchAbrirModalVacioOperario),
    fork(watchEditarOperario),
    fork(watchDuplicarDatosModalOperario),
    fork(watchCambiarEstadoOperario),
    fork(watchOnDeleteOperario),
    fork(watchFetchFiltrarOperario),
    fork(watchCambiarEstadoSeleccionOperario),
    fork(watchDeleteSeleccionOperario),
    fork(watchCrearCsvOperario),
    fork(watchGuardarYnuevoOperario),    
    fork(watchFetchNivelGenetico),
    fork(watchSubmitNuevaNivelGenetico),
    fork(watchRecuperarDatosModalNivelGenetico),
    fork(watchAbrirModalVacioNivelGenetico),
    fork(watchEditarNivelGenetico),
    fork(watchFetchMicrobiologia),
    fork(watchSubmitNuevaMicrobiologia),
    fork(watchRecuperarDatosModalMicrobiologia),
    fork(watchAbrirModalVacioMicrobiologia),
    fork(watchEditarMicrobiologia),
    fork(watchOnDeleteMicrobiologia), 
    fork(watchFetchFiltrarMicrobiologia),  
    fork(watchCrearCsvMicrobiologia),
    fork(watchFetchTipoInstalacion),
    fork(watchSubmitNuevaTipoInstalacion),
    fork(watchRecuperarDatosModalTipoInstalacion),
    fork(watchAbrirModalVacioTipoInstalacion),
    fork(watchEditarTipoInstalacion),
    fork(watchDuplicarDatosModalTipoInstalacion),
    fork(watchCambiarEstadoTipoInstalacion),
    fork(watchOnDeleteTipoInstalacion),
    fork(watchFetchFiltrarTipoInstalacion),
    fork(watchCambiarEstadoSeleccionTipoInstalacion),
    fork(watchDeleteSeleccionTipoInstalacion),
    fork(watchGuardarYnuevoTipoInstalacion),
    fork(watchComboTipoInstalacionPadre),
    fork(watchFetchInstalacion),
    fork(watchSubmitNuevaInstalacion),
    fork(watchRecuperarDatosModalInstalacion),
    fork(watchAbrirModalVacioInstalacion),
    fork(watchEditarInstalacion),
    fork(watchDuplicarDatosModalInstalacion),
    fork(watchCambiarEstadoInstalacion),
    fork(watchOnDeleteInstalacion),
    fork(watchFetchFiltrarInstalacion),
    fork(watchCambiarEstadoSeleccionInstalacion),
    fork(watchDeleteSeleccionInstalacion),
    fork(watchGuardarYnuevoInstalacion),
    fork(watchComboTipoInstalacion),
    fork(watchComboInstalacionPadre),
    fork(watchComboCodInstalacion),
    fork(watchComboNombreInstalacion),
    fork(watchComboOperario),
    fork(watchComboSalaExtraccion),
    fork(watchFetchFichaVerraco),
    fork(watchSubmitNuevoVerraco),
    fork(watchAsignarCodRaza),
    fork(watchComboCliente),
    fork(watchComboDireccionCliente),
    fork(watchComboDireccionClienteSecundaria),
    fork(watchComboDireccionClienteActivo),
    fork(watchComboRazaGranja),
    fork(watchComboLineaGeneticaGranja),
    fork(watchComboUbicacion),
    fork(watchComboTipoDosis),
    fork(watchComboTipoDosisGranja),
    fork(watchAbrirModalVacioEvolucion),
    fork(watchRecuperarDatosModalEvolucion),
    fork(watchEditarEvolucion),
    fork(watchDeleteEvolucion),
    fork(watchDuplicarDatosModalEvolucion),
    fork(watchDeleteSeleccionEvolucion),
    fork(watchAbrirModalVacioAlimentacion),
    fork(watchGetAlimentacion),
    fork(watchSubmitNuevaAlimentacion),
    fork(watchRecuperarDatosModalAlimentacion),
    fork(watchEditarAlimentacion),
    fork(watchDeleteAlimentacion),
    fork(watchDuplicarDatosModalAlimentacion),
    fork(watchDeleteSeleccionAlimentacion),
    fork(watchGuardarYnuevoAlimentacion),
    fork(watchAbrirModalVacioIndex),
    fork(watchGetIndex),
    fork(watchRecuperarDatosModalIndex),
    fork(watchEditarIndex),
    fork(watchDeleteIndex),
    fork(watchDuplicarDatosModalIndex),
    fork(watchDeleteSeleccionIndex),
    fork(watchComboObtenerCentroUsuario),
    fork(watchCambiarCentro),
    fork(watchFetchEditarVerraco),
    fork(watchSubmitEditVerraco),
    fork(watchSubmitNuevoMoviVerraco),
    fork(watchUbicacionenesVacias),
    fork(watchCambiarVigilado),
    fork(watchOnDeleteVerraco),
    fork(watchCambiarVigiladoSeleccionFichaVerraco),
    fork(watchDeleteSeleccionFichaVerraco),
    fork(watchEnviarFechaCambioEstado),
    fork(watchsubmitNuevoCambioEstadoVerraco),
    fork(watchEnviarFechaMovimientoVerraco),
    fork(watchCerrarModalCambioEstadoVerraco),
    fork(watchFetchMovimientoVerraco),
    fork(watchComboListadoVerracos),
    fork(watchComboObservacionesEyaculadoEliminado),
    fork(watchComboCausaEliminacion),
    fork(watchRecuperarDatosModalMovimientoVerraco),
    fork(watchAbrirModalVacioUbicacion),
    fork(watchEditarMoviVerraco),
    fork(watchDuplicarDatosModalMovimientoVerraco),
    fork(watchOnDeleteMovimientoVerraco),
    fork(watchFetchCambioEstadoVerraco),
    fork(watchAbrirModalVacioCambioEstado),
    fork(watchRecuperarDatosModalCambioEstadoVerraco),
    fork(watchEditarCambioEstadoVerraco),
    fork(watchDuplicarDatosModalCambioEstadoVerraco),
    fork(watchOnDeleteCambioEstadoVerraco),
    fork(watchGuardarYnuevoVerraco),
    fork(watchDeleteSeleccionMovimientoVerraco),
    fork(watchDeleteSeleccionCambioEstadoVerraco),
    fork(watchFetchVerracosGrupoVerraco),
    fork(watchAnadirVerraco),
    fork(watchOnDeleteVerracoAnadido),
    fork(watchDeleteSeleccionVerracosGrupoVerraco),
    fork(watchFetchFiltrarFichaVerraco),
    fork(watchFetchFiltrarMovimientoVerraco),
    fork(watchFetchFiltrarCambioEstadoVerraco),
    fork(watchFetchHistoricoEstadosVerraco),
    fork(watchFetchHistoricoGrupoVerraco),
    fork(watchFetchFiltrarVerracoModalVerraco),
    fork(watchFetchTipoAlimentacion),
    fork(watchAbrirModalVacioTipoAlimentacion),
    fork(watchSubmitNuevoTipoAlimentacion),
    fork(watchRecuperarDatosModalTipoAlimentacion),
    fork(watchEditarTipoAlimentacion),
    fork(watchDuplicarDatosModalTipoAlimentacion),
    fork(watchCambiarEstadoTipoAlimentacion),
    fork(watchOnDeleteTipoAlimentacion),
    fork(watchCambiarEstadoSeleccionTipoAlimentacion),
    fork(watchDeleteSeleccionTipoAlimentacion),
    fork(watchFetchVisita),
    fork(watchAbrirModalVacioVisita),
    fork(watchSubmitNuevoVisita),
    fork(watchRecuperarDatosModalVisita),
    fork(watchEditarVisita),
    fork(watchDuplicarDatosModalVisita),
    fork(watchOnDeleteVisita),
    fork(watchDeleteSeleccionVisita),
    fork(watchGuardarYnuevoVisita),
    fork(watchGuardarYnuevoTipoAlimentacion),
    fork(watchFetchFiltrarVisita),
    fork(watchFetchRegistroAlimentacion),
    fork(watchAbrirModalVacioRegistroAlimentacion),
    fork(watchSubmitNuevoRegistroAlimentacion),
    fork(watchRecuperarDatosModalRegistroAlimentacion),
    fork(watchEditarRegistroAlimentacion),
    fork(watchDuplicarDatosModalRegistroAlimentacion),
    fork(watchOnDeleteRegistroAlimentacion),
    fork(watchDeleteSeleccionRegistroAlimentacion),
    fork(watchGuardarYnuevoRegistroAlimentacion),
    fork(watchFetchFiltrarRegistroAlimentacion),
    fork(watchFetchVerracosRegistroAlimentacion),
    fork(watchAnadirVerracoModalRegistroAlimentacion),
    fork(watchFetchFiltrarVerracoModalRegistroAlimentacion),
    fork(watchOnDeleteVerracoAnadidoRegistroAlimentacion),
    fork(watchDeleteSeleccionVerracosRegistroAlimentacion),
    fork(watchComboTipoAlimentacion),
    fork(watchFetchComboTipoDiluyente),
    fork(watchFetchTipoIntervencionSanitaria),
    fork(watchAbrirModalVacioTipoIntervencionSanitaria),
    fork(watchSubmitNuevoTipoIntervencionSanitaria),
    fork(watchRecuperarDatosModalTipoIntervencionSanitaria),
    fork(watchEditarTipoIntervencionSanitaria),
    fork(watchDuplicarDatosModalTipoIntervencionSanitaria),
    fork(watchCambiarEstadoTipoIntervencionSanitaria),
    fork(watchOnDeleteTipoIntervencionSanitaria),
    fork(watchCambiarEstadoSeleccionTipoIntervencionSanitaria),
    fork(watchDeleteSeleccionTipoIntervencionSanitaria),
    fork(watchGuardarYnuevoTipoIntervencionSanitaria),
    fork(watchFetchRegistroIntervencionSanitaria),
    fork(watchAbrirModalVacioRegistroIntervencionSanitaria),
    fork(watchSubmitNuevoRegistroIntervencionSanitaria),
    fork(watchRecuperarDatosModalRegistroIntervencionSanitaria),
    fork(watchEditarRegistroIntervencionSanitaria),
    fork(watchDuplicarDatosModalRegistroIntervencionSanitaria),
    fork(watchOnDeleteRegistroIntervencionSanitaria),
    fork(watchDeleteSeleccionRegistroIntervencionSanitaria),
    fork(watchGuardarYnuevoRegistroIntervencionSanitaria),
    fork(watchFetchFiltrarRegistroIntervencionSanitaria),
    fork(watchFetchVerracosRegistroIntervencionSanitaria),
    fork(watchAnadirVerracoModalRegistroIntervencionSanitaria),
    fork(watchFetchFiltrarVerracoModalRegistroIntervencionSanitaria),
    fork(watchOnDeleteVerracoAnadidoRegistroIntervencionSanitaria),
    fork(watchDeleteSeleccionVerracosRegistroIntervencionSanitaria),
    fork(watchAbrirModalAgendaIntervencionSanitaria),
    fork(watchGuardarModalAgendaIntervencionSanitaria),
    fork(watchCerrarModalAgendaIntervencionSanitaria),
    fork(watchImprimirInformeRegistroIntervencion),
    fork(watchObtenerArchivoContentIntervencion),
    fork(watchComboVerracoSinUbicacion),
    fork(watchCambiarOperarioProcesado),
    fork(watchCambiarOperarioAlbaran),
    fork(watchUpdateComboVerraco),
    fork(watchComboNivelGenetico),
    fork(watchComboNivelGeneticoGeneral),
    fork(watchComboNivelGeneticoActivo),
    fork(watchComboNivelGeneticoActivoGranja),
    fork(watchComboCodPool),

    // Begin extracciones sagas.
    fork(watchCreateNewPool),
    fork(watchAbrirModalNuevoPoolAsignacion),
    fork(watchInitializeExtraccionesPendientesAsignar),
    fork(watchFetchAnalisisExtracciones),
    fork(watchRecuperarDatosPool),
    fork(watchObtenerNumeroPool),
    fork(watchFetchExtraccionesPendientesDeAsignar),
    fork(watchAddAnalisisPool),
    fork(watchAbrirModalAsignacionDosis),
    fork(watchAddDosisAPedido),
    fork(watchFetchLineasAlbaranes),
    fork(watchDeleteSeleccionDosisEnvasadas),
    fork(watchRemoveAnalisisPool),
    fork(watchFetchComboPool),
    fork(watchCambiarEyaculadoEliminadoAnalisisExtracciones),
    fork(watchCambiarVigiladoAnalisisExtracciones),
    fork(watchOnDeleteAnalisisExtraccion),
    fork(watchCambiarEyaculadoEliminadoSeleccionAnalisisExtracciones),
    fork(watchCambiarVigiladoSeleccionAnalisisExtracciones),
    fork(watchDeleteSeleccionAnalisisExtracciones),
    fork(watchCrearCsvAnalisisExtracciones),
    fork(watchImprimirPdfAnalisisExtracciones),
    fork(watchCalcularDiasDescanso),
    fork(watchSincronizarPendientesDataSwine),
    fork(watchSubmitFormAnalisisExtraccion),
    fork(watchRecuperarDatosFormAnalisisExtraccion),
    fork(watchInitializeFormAnalisisExtraccion),
    fork(watchFetchCodigoAnalisisExtracciones),
    fork(watchFetchDatosVerracoExtracciones),
    fork(watchChangeTipoAnalisisExtraccion),
    fork(watchFetchTokenMagavision),
    fork(watchAbrirMagavision),
    fork(watchVerInformeMagavision),
    fork(watchCerrarNuevoMagavision),
    fork(watchObtenerYGuardarResultadoSistemaAnalisis),
    fork(watchGuardarResultadoSistemaAnalisis),
    fork(watchHandleAnalisisCalcFunctionsOrden),
    fork(watchCalcConcentracionTotal),
    fork(watchCalcConcentracionUtil),
    fork(watchCalcConcentracionXDosis),
    fork(watchCalcNumeroDosis),
    fork(watchReCalcConcentracionXDosis),
    fork(watchCalcVolumenDiluyente),
    fork(watchAbrirModalDilucionAnalisisExtraccion),
    fork(watchAbrirModalPoolAnalisisExtraccion),
    fork(watchGuardarModalDilucionAnalisisExtraccion),
    fork(watchGuardarModalPoolAnalisisExtraccion),
    fork(watchCerrarModalDilucionAnalisisExtraccion),
    fork(watchPrepararMaquinaDilucionAnalisisExtraccion),
    fork(watchPdfEtiquetaExtraccion),
    fork(watchImprimirMiniEtiquetaAnalisis),
    fork(watchLecturaRFIDAnalisis),
    fork(watchShowUltimasExtraccionesVerraco),
    fork(watchImprimirEtiquetaPDF),
    fork(watchOpenModalContajeMaquinas),
    fork(watchCloseModalContajeMaquinas),
    fork(watchComprobarCamara),
    fork(watchGetImagenCamara),
    //fork(watchGetVideoCamara),
    fork(watchApagarCamara),
    fork(watchFetchPoolExtracciones),
    fork(watchOnDeletePoolExtraccion),
    fork(watchDeleteSeleccionPoolExtracciones),
    fork(watchCrearCsvPoolExtracciones),
    fork(watchImprimirPdfPoolExtracciones),
    fork(watchSubmitFormPoolExtraccion),
    fork(watchRecuperarDatosFormPoolExtraccion),
    fork(watchInitializeFormPoolExtraccion),
    fork(watchFetchCodigoPoolExtracciones),
    fork(watchHandlePoolCalcFunctionsOrden),
    fork(watchCalcConcentracionXDosisPool),
    fork(watchCalcNumeroDosisPool),
    fork(watchReCalcConcentracionXDosisPool),
    fork(watchOnDeleteAsignacionAnalisisPool),
    fork(watchDeleteSeleccionAsignacionAnalisisPool),
    fork(watchAbrirModalAsignacionAnalisisPool),
    fork(watchCerrarModalAsignacionAnalisisPool),
    fork(watchGuardarModalAsignacionAnalisisPool),
    fork(watchFetchAnalisisDisponibles),
    fork(watchDescargarEtiquetaPdf),
    fork(watchImprimirPantalla),
    fork(watchImprimirEtiquetaPDFPool),
    fork(watchImprimirMiniEtiquetaPool),
    fork(watchCrearExcelBatchUSA),
    fork(watchAbrirBascula),
    fork(watchCalcVolumenDiluyentePool),
    // End extracciones/pool sagas.

    fork(watchAbrirModalVacioBioseguridadHigiene),
    fork(watchFetchBioseguridadHigiene),
    fork(watchFetchLimpiezaSilos),
    fork(watchAbrirModalLimpiezaSilos),
    fork(watchOnDeleteSiloAnadido),
    fork(watchDeleteSeleccionModalSilos),
    fork(watchFetchLimpiezaCorral),
    fork(watchAbrirModalLimpiezaCorral),
    fork(watchOnDeleteCorralAnadido),
    fork(watchDeleteSeleccionModalCorral),
    fork(watchFetchUbicacion),
    fork(watchAbrirModalUbicacion),
    fork(watchOnDeleteUbicacionAnadido),
    fork(watchDeleteSeleccionModalUbicacion),
    fork(watchFetchVerraco),
    fork(watchAbrirModalVerraco),
    fork(watchOnDeleteVerracoAnadidoBioseguridad),
    fork(watchDeleteSeleccionModalVerraco),
    fork(watchFetchGrupoVerraco),
    fork(watchAbrirModalGrupoVerraco),
    fork(watchOnDeleteGrupoVerracoAnadidoBioseguridad),
    fork(watchDeleteSeleccionModalGrupoVerraco),
    fork(watchSubmitAnaliticaAgua),
    fork(watchSubmitLimpiezaSilos),
    fork(watchSubmitLimpiezaCorrales),
    fork(watchSubmitOtros),
    fork(watchRecuperarDatosModalBioseguridadHigiene),
    fork(watchModificarAnaliticaAgua),
    fork(watchModificarLimpiezaSilos),
    fork(watchModificarLimpiezaCorral),
    fork(watchModificarOtros),
    fork(watchDuplicarDatosModalBioseguridadHigiene),
    fork(watchOnDeleteBioseguridadHigiene),
    fork(watchGuardarNuevoAnaliticaAgua),
    fork(watchGuardarNuevoLimpiezaSilos),
    fork(watchGuardarNuevoLimpiezaCorrales),
    fork(watchGuardarNuevoOtros),
    fork(watchDeleteSeleccionBioseguridadHigiene),
    fork(watchFetchFiltrarBioseguridadHigiene),
    fork(watchComboTipoIntervencionSanitaria),
    fork(watchFetchDilucion),
    fork(watchAbrirDetalles),
    fork(watchComboMaquina),
    fork(watchEditarDilucion),
    fork(watchPrepararMaquinaDilucion),
    fork(watchCerrarDetalles),
    fork(watchFetchFiltrarDilucion),
    fork(watchfetchRuta),
    fork(watchAbrirModalVacioRuta),
    fork(watchOnDeleteClienteAnadido),
    fork(watchSubmitNuevoRuta),
    fork(watchFetchClienteRuta),
    fork(watchDeleteSeleccionClientesRuta),
    fork(watchRecuperarDatosModalRuta),
    fork(watchEditarRuta),
    fork(watchDuplicarDatosModalRuta),
    fork(watchGuardarYnuevoRuta),
    fork(watchCambiarEstadoRuta),
    fork(watchOnDeleteRuta),
    fork(watchDeleteSeleccionRuta),
    fork(watchCambioEstadoSeleccionRuta),
    fork(watchComboRuta),
    fork(watchFetchFiltrarRuta),
    fork(watchComboCifCliente),
    fork(watchComboLocalidadCliente),
    fork(watchFetchFiltrarClienteRuta),
    fork(watchAbrirModalConductor),
    fork(watchRecuperarModalCondutor),
    fork(watchEditConductorAnadido),
    fork(watchCambiarEstadoConductor),
    fork(watchOnDeleteConductorAnadido),
    fork(watchComboInstalacionesPadre),
    fork(watchComboNombresOperarios),
    fork(watchComboApellidosOperarios),
    fork(watchComboLocalidadOperarios),
    fork(watchComboEmpresaVisitas),
    fork(watchComboContactoVistas),
    fork(watchComboLineaGeneticaActiva),
    fork(watchComboRazaActiva),
    fork(watchComboOperarioActivo),
    fork(watchComboSalaExtraccionActivo),
    fork(watchFetchComboRectaCalibrado),
    fork(watchObtenerPresenciaVerraco),
    fork(watchImprimirInformeVisita),
    fork(watchGuardarYNuevoMovimiento),
    fork(watchFetchTransportista),
    fork(watchFiltrarTrasporte),
    fork(watchDuplicarConductor),
    fork(watchConductorPorDefecto),
    fork(watchAbrirModalVehiculo),
    fork(watchRecuperarModalVehiculo),
    fork(watchEditVehiculoAnadido),
    fork(watchCambiarEstadoVehiculo),
    fork(watchOnDeleteVehiculoAnadido),
    fork(watchDuplicarVehiculo),
    fork(watchVehiculoPorDefecto),
    fork(watchFiltrarRutasTransporte),
    fork(watchAbrirModalRuta),
    fork(watchOnDeleteRutaAnadida),
    fork(watchRutaPorDefecto),
    fork(watchSubmitNuevoTransportista),
    fork(watchGuardarYnuevoTransporte),
    fork(watchFetchEditarTransportista),
    fork(watchSubmitEditarTransportista),
    fork(watchCambiarEstadoTransporte),
    fork(watchOnDeleteTransporte),
    fork(watchCambioEstadoSeleccionTransporte),
    fork(watchDeleteSeleccionTransporte),
    fork(watchComboEmpresaTransportista),
    fork(watchComboConductoresTransportista),
    fork(watchComboVehiculosTransportista),
    fork(watchFetchDuplicarVerraco),
    fork(watchObtenerArchivoContent),
    fork(watchImprimirPdfRaza),
    fork(watchImprimirPdfLinea),
    fork(watchImprimirPdfGrupoVerraco),
    fork(watchImprimirPdfOperario),
    fork(watchImprimirEtiquetaOperario),
    fork(watchCrearCsvRuta),
    fork(watchImprimirPdfRuta),
    fork(watchCrearCsvTransporte),
    fork(watchImprimirPdfTransporte),
    fork(watchCrearCsvTipoInstalacion),
    fork(watchImprimirPdfTipoInstalacion),
    fork(watchCrearCsvInstalacion),
    fork(watchImprimirPdfInstalacion),
    fork(watchCrearCsvBioseguridad),
    fork(watchImprimirPdfBioseguridad),
    fork(watchAbrirModalAgendaBioseguridad),
    fork(watchGuardarModalAgendaBioseguridad),
    fork(watchCerrarModalAgendaBioseguridad),
    fork(watchObtenerArchivoContentBioseguridad),
    fork(watchImprimirInformeBioseguridad),
    fork(watchCrearCsvVisita),
    fork(watchImprimirPdfVisita),
    fork(watchCrearCsvTipoAlimentacion),
    fork(watchImprimirPdfTipoAlimentacion),
    fork(watchCrearCsvRegistroAlimentacion),
    fork(watchImprimirPdfRegistroAlimentacion),
    fork(watchImprimirInformeRegistroAlimentacion),
    fork(watchCrearCsvTipoIntervencion),
    fork(watchImprimirPdfTipoIntervencion),
    fork(watchCrearCsvRegistroIntervencion),
    fork(watchImprimirPdfRegistroIntervencion),
    fork(watchCrearCsvFichaVerraco),
    fork(watchImprimirPdfFichaVerraco),
    fork(watchCrearCsvCambioEstado),
    fork(watchImprimirPdfCambioEstado),
    fork(watchCrearCsvMovimientoVerraco),
    fork(watchImprimirPdfMovimientoVerraco),
    fork(watchObtenerDatosPanelControl),
    fork(watchGuardarPanelControl),
    fork(watchObtenerValoresTeclasContaje),
    fork(watchValoresPredefinidosTeclasContaje),
    fork(watchGuardarTeclasContaje),
    fork(watchAbrirModalTeclasContaje),
    fork(watchAnadirDiluyentePanelControl),
    fork(watchAbrirModalTipoDiluyente),
    fork(watchRecuperarDatosTipoDiluyente),
    fork(watchEditarDiluyentePanelControl),
    fork(watchDuplicarDatosModalTipoDiluyente),
    fork(watchCambiaPredeterminadoDiluyente),
    fork(watchOnDeleteDiluyente),
    fork(watchDeleteSeleccionTipoDiluyente),
    fork(watchAnadirDosisPanelControl),
    fork(watchAbrirModalTipoDosis),
    fork(watchRecuperarDatosTipoDosis),
    fork(watchEditarDosisPanelControl),
    fork(watchDuplicarDatosModalTipoDosis),
    fork(watchCambiaPredeterminadoDosis),
    fork(watchOnDeleteDosis),
    fork(watchDeleteSeleccionTipoDosis),
    fork(watchSubmitAnadirColorimetro),
    fork(watchFetchRectaColorimetro),
    fork(watchRecuperarDatosRectaColorimetro),
    fork(watchEditarColorimetro),
    fork(watchDuplicarDatosModalRectaColorimetro),
    fork(watchCambiaPredeterminadoRectaColorimetro),
    fork(watchOnDeleteRectaColorimetro),
    fork(watchAbrirModalAnadirMuestras),
    fork(watchRecuperarModalMuestras),
    fork(watchEditMuestrasAnadidas),
    fork(watchDuplicarModalMuestras),
    fork(watchOnDeleteMuestras),
    fork(watchDeleteSeleccionRectaColorimetro),
    fork(watchFetchListadoVerracos),
    fork(watchAnadirVerracoRecta),
    fork(watchEditarVerracoRecta),
    fork(watchValoresPredefinidosPanelControl),
    fork(watchSubmitNuevoProducto),
    fork(watchOnDeleteVerracos),
    fork(watchAnadirMuestra),
    fork(watchComboUsuariosCentro),
    fork(watchComboGrupoUsuariosCentro),
    fork(watchComboNombreProductos),
    fork(watchFiltrarProducto),
    fork(watchComboReferenciaProductos),
    fork(watchRecuperarDatosModalProducto),
    fork(watchAbrirProducto),
    fork(watchEditarProducto),
    fork(watchDuplicarDatosModalProducto),
    fork(watchCambiarEstadoProducto),
    fork(watchOnDeleteProducto),
    fork(watchCambiarEstadoSeleccionProducto),
    fork(watchDeleteSeleccionProducto),
    fork(watchCrearCsvProducto),
    fork(watchImprimirPdfProducto),
    fork(watchFiltrarEscandallo),
    fork(watchAbrirEscandallo),
    fork(watchComboNombreEscandallo),
    fork(watchComboProductoEscandallo),
    fork(watchSubmitNuevoEscandallo),
    fork(watchRecuperarDatosModalEscandalloAnadido),
    fork(watchEditarEscandalloAnadido),
    fork(watchOnDeleteEscandalloAnadido),
    fork(watchDuplicarDatosModalEscandalloAnadido),
    fork(watchRecuperarDatosModalEscandallo),
    fork(watchDuplicarDatosModalEscandallo),
    fork(watchEditarEscandallo),
    fork(watchGuardarYnuevoEscandallo),
    fork(watchCambiarEstadoEscandallo),
    fork(watchOnDeleteEscandallo),
    fork(watchCambiarEstadoSeleccionEscandallo),
    fork(watchDeleteSeleccionEscandallo),
    fork(watchCrearCsvEscandallo),
    fork(watchImprimirPdfEscandallo),
    fork(watchComprobarStockTrazabilidad),
    fork(watchUpdateStockInsuficiente),
    fork(watchUpdateLotesEscandallo),
    fork(watchObtenerLotesEscandalloAnalisis),
    fork(watchObtenerLotesEscandalloEnvasado),
    fork(watchOpenModalTrazabilidadMaterial),
    fork(watchActualizarTrazabilidadMaterial),
    fork(watchGuardarModalTrazabilidadMaterial),
    fork(watchCloseModalTrazabilidadMaterial),
    fork(watchDeleteTrazabilidadMaterial),
    fork(watchDeleteSeleccionTrazabilidadMaterial),
    fork(watchOpenModalEditarTrazabilidadMaterial),
    fork(watchGuardarModalEditarTrazabilidadMaterial),
    fork(watchCloseModalEditarTrazabilidadMaterial),
    fork(watchFetchMaterialDisponible),
    fork(watchOpenModalMaterialDisponible),
    fork(watchGuardarModalMaterialDisponible),
    fork(watchCloseModalMaterialDisponible),
    fork(watchFiltrarEntradaMercancia),
    fork(watchComboProveedoresEntradaMercancia),
    fork(watchComboAlbaranesEntradaMercancia),
    fork(watchComboOrigenesEntradaMercancia),
    fork(watchRecuperarModalEntradaMercanciaProductoAnadido),
    fork(watchEditarEntradaMercanciaProducto),
    fork(watchAbrirModalEntradaMercanciaAnadirProducto),
    fork(watchDuplicarDatosModalEntradaMercanciaAnadido),
    fork(watchOnDeleteEntradaMercanciaProducto),
    fork(watchGuardarYnuevoEntradaMercanciaProducto),
    fork(watchSubmitNuevoEntradaMercancia),
    fork(watchRecuperarDatosModalEntradaMercancia),
    fork(watchEditarEntradaMercancia),
    fork(watchAbrirModalEntradaMercancia),
    fork(watchDuplicarDatosModalEntradaMercancia),
    fork(watchGuardarYnuevoEntradaMercancia),
    fork(watchOnDeleteEntradaMercancia),
    fork(watchDeleteSeleccionEntradaMercancia),
    fork(watchDeleteSeleccionEntradaMercanciaProductoAnadido),
    fork(watchCrearCsvEntradaMercancia),
    fork(watchImprimirPdfEntradaMercancia),
    fork(watchComboLotes),
    fork(watchComboLotesActivos),
    fork(watchFiltrarValidacionMateriaPrima),
    fork(watchAbrirValidacionMateriaPrima),
    fork(watchComboProductosActivos),
    fork(watchComboProductosActivosTrazabilidad),
    fork(watchComboLotesProductoEspecificoActivo),
    fork(watchComboLotesProductoEspecificoActivoTrazabilidad),
    fork(watchObtenerVerracosMateriaPrima),
    fork(watchObtenerPoolMateriaPrima),
    fork(watchRecuperarDatosModalVerracoPool),
    fork(watchEditarVerracoPool),
    fork(watchDuplicarDatosModalVerracoPool),
    fork(watchOnDeleteVerracoPool),
    fork(watchSubmitNuevoValidacionMateriaPrima),
    fork(watchRecuperarDatosModalValidacionMateriaPrima),
    fork(watchEditarValidacionMateriaPrima),
    fork(watchDuplicarDatosModalValidacionMateriaPrima),
    fork(watchOnDeleteValidacionMateriaPrima),
    fork(watchDeleteSeleccionValidacionMateriaPrima),
    fork(watchCrearCsvValidacionMateriaPrima),
    fork(watchEditarVerracoMateriaPrima),
    fork(watchEditarPoolMateriaPrima),
    fork(watchImprimirPdfValidacionMateriaPrima),
    fork(watchGuardarYnuevoValidacionMateriaPrima),

    // BEGIN AJUSTE STOCK SAGAS.
    fork(watchFetchAjusteStock),
    fork(watchOpenModalAjusteStock),
    fork(watchCloseModalAjusteStock),
    fork(watchGuardarAjusteStock),
    fork(watchCrearCsvAjusteStock),
    fork(watchImprimirPdfAjusteStock),
    // END AJUSTE STOCK SAGAS.

    fork(watchFetchHistoricoExtracciones),
    fork(watchFetchHistoricoIntervenciones),
    fork(watchFetchEnvasado),
    fork(watchFetchFiltrarEnvasado),
    fork(watchCrearCsvEnvasado),
    fork(watchImprimirPdfEnvasado),
    fork(watchAbrirDetallesEnvasado),
    fork(watchCerrarDetallesEnvasado),
    fork(watchEditarEnvasado),
    fork(watchDeleteEnvasadoMultiDosis),
    fork(watchDeleteSeleccionEnvasadoMultiDosis),
    fork(watchAbrirModalEnvasadoMultiDosis),
    fork(watchAbrirModalEnvasadoMultiDosisDesdeListadoPrincipal),
    fork(watchGuardarModalEnvasadoMultiDosis),
    fork(watchPrepararMaquinaEnvasado),
    fork(watchCerrarModalEnvasadoMultiDosis),
    fork(watchUpdateTotalUtilizadoDetalleEnvasado),
    fork(watchlecturaRFIDEnvasado),
    fork(watchDeleteSeleccionDosis),    
    fork(watchCrearCsvDilucion),
    fork(watchImprimirPdfDilucion),
    fork(watchDeleteSeleccionDilucion),
    fork(watchOnDeleteDilucion),
    fork(watchFiltrarCliente),
    fork(watchRecuperarDatosModalCliente),
    fork(watchSubmitNuevoCliente),
    fork(watchEditarCliente),
    fork(watchDuplicarDatosModalCliente),
    fork(watchCambiarEstadoCliente),
    fork(watchGuardarYnuevoCliente),
    fork(watchOnDeleteCliente),
    fork(watchCambiarEstadoSeleccionCliente),
    fork(watchDeleteSeleccionCliente),
    fork(watchCrearCsvCliente),
    fork(watchImprimirPdfCliente),
    fork(watchComboRazonSocialClientesActivos),
    fork(watchAbrirCliente),
    fork(watchRecuperarDatosModalDireccionCliente),
    fork(watchEditarDireccionCliente),
    fork(watchDuplicarDatosModalDireccionCliente),
    fork(watchOnDeleteDireccionCliente),
    fork(watchComboAliasClientesActivos),
    fork(watchFetchFiltrarPlaning),
    fork(watchAnadirFechaPlaning),
    fork(watchEliminarFechaPlaning),
    fork(watchAsignarVerrracos),
    fork(watchAnadirPlaning),
    fork(watchCargarVerracosPlanificados),
    fork(watchOnDeleteVerracoPlaning),
    fork(watchObtenerVerracosSinExtraer),
    fork(watchImprimirModalInicioPlaning),
    fork(watchAbrirModalPlaning),
    fork(watchDeleteSeleccionVerracoPlaning),
    fork(watchExportarModalInicioPlaning),
    fork(watchTotalesPlaning),
    fork(watchTotalesPlaningAlbaran),
    fork(watchExportarPlaning),
    fork(watchImprimirPlaning),
    fork(watchObtenerPrevisionVerracos),
    fork(watchImprimirZebraVerracos),
    fork(watchComboIndex),
    fork(watchComboUbicacionPadre),
    fork(watchComboVerracosPresentesActivos),
    fork(watchComboVerracosExtraccionAlbaran),
    fork(watchComboPoolsExtraccionAlbaran),
    fork(watchRecuperarDatosModalDatosDosis),
    fork(watchEditarDatosModalDatosDosis),
    fork(watchDuplicarDatosModalDatosDosis),
    fork(watchOnDeleteDatosDosis),
    fork(watchComboClienteActivo),
    fork(watchComboClienteSecundarioActivo),
    fork(watchComboClienteConVerracosEnPropiedad),
    fork(watchAnadirPedidosVenta),
    fork(watchAnadirPrevisionPedidos),
    fork(watchFetchFiltrarPedidosVenta),
    fork(watchElegirClientePedidoVenta),
    fork(watchElegirClienteSecundarioPedidoVenta),
    fork(watchRecuperarDatosModalPedidosVenta),
    fork(watchEditarPedidosVenta),
    fork(watchDuplicarDatosModalPedidosVenta),
    fork(watchOnDeletePedidosVenta),
    fork(watchDeleteSeleccionPedidosVenta),
    fork(watchConfirmarSeleccionPedidosVenta),
    fork(watchComboNumeroPedido),
    fork(watchExportarPedidosVenta),
    fork(watchImprimirPedidosVenta),
    fork(watchVerracosPedido),
    fork(watchCrearAlbaranDesdePedido),
    fork(watchComboTransportistasCliente),
    fork(watchComboRutaTransportistaCliente),
    fork(watchComboVehiculosTransportistaDeterminado),
    fork(watchComboConductoresTransportistaDeterminado),
    fork(watchAbrirDetallesPedido),
    fork(watchComboNumerosAlbaran),
    fork(watchDuplicarPedidoVenta),
    fork(watchObtenerNumeroPedido),
    fork(watchElegirDireccionClientePedidoVenta),
    fork(watchConfirmarYnuevoPedidosVenta),
    fork(watchDescargarPedidosCloud),
    fork(watchComboEmpresaTransportistaActivos),
    fork(watchOnDeleteRutaCliente),
    fork(watchOnDeleteDatosArticuloDosis),
    fork(watchComboRutasNoDireccionCliente),
    fork(watchAnadirDatosRuta),
    fork(watchDatosArticuloDosis),
    fork(watchComboInstalacionPadreActiva),
    fork(watchComboFiltroVerracosLimpieza),
    fork(watchImprimirDetalleVerraco),
    fork(watchAbrirModalHistoricoGrupos),
    fork(watchAbrirModalHistoricoExtracciones),
    fork(watchAbrirModalHistoricoEstado),
    fork(watchAbrirModalHistoricoIntervenciones),
    fork(watchFetchFiltrarFoso),
    fork(watchObtenerDatosVerraco),
    fork(watchObtenerExtraccion),
    fork(watchGuardarFoso),
    fork(watchModificarFoso),
    fork(watchEliminarFoso),
    fork(watchExportarFoso),
    fork(watchImprimirFoso),
    fork(watchImprimirEtiquetaZebra),
    fork(watchComboLocalidadClientes),
    fork(watchComboProvinciasClientes),
    fork(watchComboUsuariosNoAsignadosOperario),
    fork(watchComboOperarioPredeterminado),
    fork(watchComboProductosValidacionActivos),
    fork(watchComboNombreGrupoVerracoActiva),
    fork(watchComboTodasUbicaciones),
    fork(watchComboRutasActivas),
    fork(watchComboInstalacionesPadreOSinHijos),
    fork(watchComboVerracoConSeguimiento),
    fork(watchComboDireccionClienteSinPredeterminado),
    fork(watchFetchNuevaConectividad),
    fork(watchFetchFiltrarConectividad),
    fork(watchRecuperarDatosModalConectividad),
    fork(watchEditarConectividad),
    fork(watchDuplicarDatosModalConectividad),
    fork(watchCambiarEstadoConectividad),
    fork(watchOnDeleteConectividad),
    fork(watchCambiarEstadoSeleccionConectividad),
    fork(watchDeleteSeleccionConectividad),
    fork(watchCrearCsvConectividad),
    fork(watchImprimirPdfConectividad),
    fork(watchGuardarYnuevoConectividad),
    fork(watchRecuperarSecuenciaNumeraciones),
    fork(watchFetchSecuenciasNumeracion),
    fork(watchEditarSecuencia),
    fork(watchResetPedido),
    fork(watchAbrirConfiguracionMagavision),
    fork(watchActualizarConectividadVpn),
    fork(watchObternebternerConectividadVpn),
    fork(watchObtenerInformeExtracciones),
    fork(watchObtenerInformeStockDosis),
    fork(watchObtenerInformePool),
    fork(watchObtenerInformeSeguimientoExtraccionesPool),
    fork(watchObtenerInformeProductividadExtracciones),
    fork(watchObtenerInformeProductividadCentro),
    fork(watchObtenerInformeTrazabilidad),
    fork(watchObtenerInformeIntervenciones),
    fork(watchObtenerInformeCliente),
    fork(watchActualizarConectividadVpnVacio),
    fork(watchFetchEmpresa),
    fork(watchEditarEmpresa),
    fork(watchSubmitNuevoCentro),
    fork(watchRecuperarDatosCentro),
    fork(watchEditarCentro),
    fork(watchDuplicarCentro),
    fork(watchCambiarEstadoCentro),
    fork(watchEliminarCentro),
    fork(watchDeleteSeleccionCentros),
    fork(watchCambioEstadoSeleccionCentros),
    fork(watchCrearCsvEmpresa),
    fork(watchImprimirPdfEmpresa),
    fork(watchFetchUsuarios),
    fork(watchFetchCentrosUsuarios),
    fork(watchFetchUsuarioGrupoUsuarios),
    fork(watchSubmitNuevoUsuario),
    fork(watchRecuperarDatosUsuario),
    fork(watchEditarUsuarios),
    fork(watchDuplicarUsuario),
    fork(watchCambiarEstadoUsuario),
    fork(watchEliminarUsuario),
    fork(watchDeleteSeleccionUsuario),
    fork(watchCambioEstadoSeleccionUsuario),
    fork(watchCrearCsvUsuarios),
    fork(watchImprimirPdfUsuarios),
    fork(watchCambiarPasswordUsuario),
    fork(watchEditarPerfil),
    fork(watchFetchPerfil),
    fork(watchCambiarPasswordPerfil),
    fork(watchFetchPlano),
    fork(watchObtenerRegistrosSeguimientosExtraccionesPool),
    fork(watchFetchCopiasSeguridad),
    fork(watchSubmitNuevaCopiaSeguridad),
    fork(watchDescargarCopiaSeguridad),
    fork(watchOnDeleteCopiaSeguridad),
    fork(watchDeleteSeleccionCopiaSeguridad),
    fork(watchRestaurarCopiaSeguridad),
    fork(watchObtenerProgramacionCopiaSeguridad),
    fork(watchObtenerProgramacionCopiaSeguridadProgramada),
    fork(watchProbarConexionVpn),
    fork(watchObtenerVerracosProductividadExtracciones),
    fork(watchObtenerRecomendacionesMatadero),
    fork(watchFetchDashboard),
    fork(watchFetchDashboardProduccion),
    fork(watchExportarSugerenciaExtracciones),
    fork(watchImprimirSugerenciaExtracciones),
    fork(watchAnadirSeleccionPlaning),
    fork(watchEnviarFechaDuplicarPlaning),
    fork(watchSubmitDuplicarPlaning),
    fork(watchAnadirSeleccionPlaningPrincipal),
    fork(watchEliminarSeleccionPlaningPrincipal),
    fork(watchObtenerInformeEstadoCentro),
    fork(watchSubmitCopiaSeguridadDesdeArchivo),
    fork(watchSubmitSubmitRestaurarMigracion),
    fork(watchCambiarTokenMagavision),
    fork(watchSubmitCambiarTokenMagavision),
    fork(watchSubmitCargaIndex),
    fork(watchDescargarPlantillaCsv),
    fork(watchSubmitImportarDatos),
    fork(watchSubmitImportarVerracos),
    fork(watchDescargarPlantillaExcelVerracos),
    fork(watchSubmitImportarPedidos),
    fork(watchDescargarPlantillaExcelPedidos),
    fork(watchDescargarVerracos),
    fork(watchImprimirPdfInformesExtracciones),
    fork(watchImprimirPdfInformesPool),
    fork(watchImprimirPdfInformesProductividadExtracciones),
    fork(watchImprimirPdfInformesSeguimientoExtraccionesPool),
    fork(watchImprimirPdfInformesProductividadCentro),
    fork(watchImprimirPdfInformesTrazabilidad),
    fork(watchImprimirPdfInformesIntervenciones),
    fork(watchImprimirPdfInformesClientes),
    fork(watchImprimirPdfInformesStockDosis),
    fork(watchCrearCsvInformeExtracciones),
    fork(watchCrearCsvInformePool),
    fork(watchObtenerNotificaconesUsuario),
    fork(watchMarcarComoLeidoNotificacion),
    fork(watchMarcarComoLeidoNotificacionStock),
    fork(watchCrearCsvInformeProductividadExtracciones),
    fork(watchImprimirPdfInformesProductividadExtraccionesVerraco),
    fork(watchCrearCsvInformeProductividadExtraccionesVerraco),
    fork(watchImprimirPdfInformesProductividadExtraccionesMatadero),
    fork(watchCrearCsvInformeProductividadExtraccionesMatadero),
    fork(watchCrearCsvInformeStockDosis),
    fork(watchCrearCsvInformeClientes),
    fork(watchCrearCsvInformeTrazabilidad),
    fork(watchCrearCsvInformeIntervenciones),
    fork(watchCrearCsvInformeProductividadCentro),
    fork(watchImprimirPdfInformesEstadoCentro),
    fork(watchCrearCsvInformesEstadoCentro),
    fork(watchExportarInformeSeguimientoExtraccionesPool),
    fork(watchMostrarSeleccionInformePersonalizado),
    fork(watchAbrirModalGuardarSeleccion),
    fork(watchSubmitGuardarSeleccionInformePersonalizado),
    fork(watchComboSeleccionesInformePersonalizado),
    fork(watchCargarSeleccionInformePersonalizado),
    fork(watchEliminarSeleccionInformePersonalizado),
    fork(watchImprimirPdfInformePersonalizado),
    fork(watchCrearCsvInformePersonalizado),

    // BEGIN GRUPO USUARIOS SAGAS.
    fork(watchFetchGrupoUsuarios),
    fork(watchAbrirModalGrupoUsuarios),
    fork(watchDuplicarGrupoUsuarios),
    fork(watchSubmitGrupoUsuarios),
    fork(watchCerrarModalGrupoUsuarios),
    fork(watchEliminarGrupoUsuarios),
    fork(watchCambiarEstadoGrupoUsuarios),
    fork(watchCrearCsvGrupoUsuarios),
    fork(watchImprimirPdfGrupoUsuarios),
    fork(watchDeleteSeleccionGrupoUsuarios),
    fork(watchCambioEstadoSeleccionGrupoUsuarios),
    // END GRUPO USUARIOS SAGAS.

    // BEGIN SEGUIMIENTO SAGAS.
    fork(watchFetchSeguimientos),
    fork(watchOpenModalListadoSeguimiento),
    fork(watchCloseModalListadoSeguimiento),
    fork(watchDeleteSeguimiento),
    fork(watchDeleteSeleccionSeguimiento),
    fork(watchCrearCsvSeguimiento),
    fork(watchImprimirPdfSeguimiento),
    fork(watchOpenModalFormSeguimiento),
    fork(watchGuardarModalFormSeguimiento),
    fork(watchCloseModalFormSeguimiento),
    fork(watchObtenerYGuardarResultadoSistemaAnalisisSeguimiento),
    fork(watchFetchFiltrarModalNuevoSeguimiento),
    fork(watchObtenerYGuardarResultadoSistemaAnalisisMultiSeguimiento),
    // END SEGUIMIENTO SAGAS.

    // BEGIN ALBARAN SAGAS.
    fork(watchFetchAlbaranes),
    fork(watchDuplicarDatosModalAlbaran),
    fork(watchOnDeleteAlbaran),
    fork(watchImprimirAlbaran),
    fork(watchImprimirDetalleAlbaran),    
    fork(watchDeleteSeleccionAlbaranes),
    fork(watchCrearCsvAlbaranes),
    fork(watchImprimirPdfAlbaranes),
    fork(watchAbrirModalVacioAlbaran),
    fork(watchAbrirDetallesAlbaran),
    fork(watchCerrarDetallesAlbaran),
    fork(watchEditarAlbaran),
    fork(watchAbrirModalVacioAlbaranDosis),
    fork(watchAbrirDetallesAlbaranDosis),
    fork(watchCerrarDetallesAlbaranDosis),
    fork(watchEditarAlbaranDosis),
    fork(watchDeleteModalAlbaranDosis),
    fork(watchDeleteSeleccionModalAlbaranDosis),
    fork(watchCalcTotalesAlbaranDosis),
    fork(watchEditarAlbaranEImprimirDatosAlbaran),
    fork(watchImprimirDatosAlbaran),
    fork(watchImprimirDatosAlbaranCliente),
    // END ALBARAN SAGAS.

      // BEGIN AGENDA SAGAS.
    fork(watchFetchAgenda),
    fork(watchFetchTareasDia),
    fork(watchAbrirModalAgenda),
    fork(watchGuardarModalAgenda),
    fork(watchCloseModalAgenda),
    fork(watchOnDeleteAgenda),
    fork(watchOnDeleteAgendaSerie),
    // END AGENDA SAGAS.

      // BEGIN LICENCIAS SAGAS.
    fork(watchFetchLicencias),
    fork(watchAbrirModalLicencias),
    fork(watchSubmitLicencia),
    fork(watchCerrarModalLicencias),
    fork(watchOnDeleteLicencia),
    // END LICENCIAS SAGAS.

    // CONTROL CALIDAD
    // CONTROL MORFOLÓGICO
    fork(watchFetchControlMorfologico),
    fork(watchOpenModalListadoControlMorfologico),
    fork(watchCloseModalListadoControlMorfologico),
    fork(watchDeleteControlMorfologico),
    fork(watchDeleteSeleccionControlMorfologico),
    fork(watchCrearCsvControlMorfologico),
    fork(watchImprimirPdfControlMorfologico),
    fork(watchOpenModalFormControlMorfologico),
    fork(watchGuardarModalFormControlMorfologico),
    fork(watchCloseModalFormControlMorfologico),
    fork(watchObtenerYGuardarResultadoSistemaAnalisisControlMorfologico),
    fork(watchFetchFiltrarModalInicio),
    fork(watchAnadirVerracosModalInicio),
    fork(watchImprimirEtiquetaPDFAlbaran),
    fork(watchLeerEtiquetasRFID),
    fork(watchGuardarResultadoSistemaAnalisisControlMorfologico),
    
    // CONTROL SANGRE
    fork(watchFetchControlSangre),
    fork(watchAnadirVerracosModalInicioControlSangre),
    fork(watchFetchFiltrarModalInicioControlSangre),
    fork(watchDeleteControlSangre),
    fork(watchCrearCsvControlSangre),
    fork(watchImprimirPdfControlSangre),
    fork(watchDescargarArchivoControlSangre),
    fork(watchDeleteSeleccionControlSangre),
    fork(watchimprimirEtiquetaControlSangre),
    fork(watchRecuperarDatosModalControlSangre),   
    fork(watchguardarDetalleControlSangre),     
    
    // CONTROL SEMEN
    fork(watchFetchControlSemen),
    fork(watchAnadirVerracosModalInicioControlSemen),
    fork(watchFetchFiltrarModalInicioControlSemen),
    fork(watchDeleteControlSemen),
    fork(watchDeleteSeleccionControlSemen),
    fork(watchCrearCsvControlSemen),
    fork(watchImprimirPdfControlSemen),
    fork(watchDescargarArchivoControlSemen),
    fork(watchimprimirEtiquetaControlSemen),
    fork(watchRecuperarDatosModalControlSemen),   
    fork(watchguardarDetalleControlSemen),     

    // COMBO CAUSA ELIMINACION
    fork(watchFetchComboCausaEliminacion),
    fork(watchSubmitNuevaComboCausaEliminacion),
    fork(watchRecuperarDatosModalComboCausaEliminacion),
    fork(watchAbrirModalVacioComboCausaEliminacion),
    fork(watchEditarComboCausaEliminacion),
    // COMBO OBSERVACIONES EYACULADO ELIMINADO
    fork(watchFetchComboObservacionesEyaculadoEliminado),
    fork(watchSubmitNuevaComboObservacionesEyaculadoEliminado),
    fork(watchRecuperarDatosModalComboObservacionesEyaculadoEliminado),
    fork(watchAbrirModalVacioComboObservacionesEyaculadoEliminado),
    fork(watchEditarComboObservacionesEyaculadoEliminado),
    //PICTraq
    fork(watchFetchAnalisisParaPIC),
    fork(watchfetchPoolParaPIC),
    fork(watchFetchPICTraits),
    fork(watchInsertAnalisisPIC),
    fork(watchInsertPoolPIC),
    fork(watchInsertSplitsPIC),
    fork(watchSaveDataPIC),
    fork(watchSaveDataPoolPIC),
    fork(watchSaveDataSplitsPIC),
    fork(watchGetUrlPICSaga),
    fork(watchSincronizarIndexPICSaga)
  ])
}
