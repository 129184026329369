import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { Row, Col, Modal } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import Button from '../comun/button/Button'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import InputNumericalWithCountKeys from '../comun/form/InputNumericalWithCountKeys'
import FormGroupSubtitle from '../comun/form/FormGroupSubtitle'
import CamaraViewPage from '../../containers/analisisExtracciones/CamaraViewPage'
import {tipoAnalisisExtraccion as tipoAnalisisExtraccionConstants, translateTipoAnalisisExtraccion} from '../../constants/analisis'
import { onSubmitFail } from '../../util/util'
import { round_decimal } from '../../util/formatFunctions'

class ModalContajeMaquinas extends Component {
  constructor (props) {
    super()
    this.state = {
      ...props.initialValues,
      limiteCuadrosContadosAlcanzado: false
    }
    this.onChangeInputAcrosomasDannados = this.onChangeInputAcrosomasDannados.bind(this)
    this.onChangeInputContajeMaquinas = this.onChangeInputContajeMaquinas.bind(this)
    this.onChangeInputCuadrosContados = this.onChangeInputCuadrosContados.bind(this)
    this.submitContajeMaquinas = this.submitContajeMaquinas.bind(this)

    this.setAceptarButtonRefFn = this.setAceptarButtonRefFn.bind(this)
    this.aceptarButtonRef = null
  }

  setAceptarButtonRefFn (ref) {
    return this.aceptarButtonRef = ref
  }

  onChangeInputAcrosomasDannados (value) {    
    this.setState((state) => {
      state.acrosomasDannados = value
      state.acrosomas = 100 / state.espermatozoidesContados * value
      if (isNaN(state.acrosomas) || !isFinite(state.acrosomas)) {
        state.acrosomas = 0
      }
      this.props.change('acrosomas', state.acrosomas)
      return state;
    })
  }

  onChangeInputContajeMaquinas (name, value) {    
    this.setState((state) => {
      let newState = {...state, [name]: value}
      newState.espermatozoidesContados = newState.cabeza + newState.cola + newState.gotaDistal + newState.gotaProximal + newState.piezaIntermedia + newState.buenos
      newState.totalFormasAnormales = '' + ((newState.espermatozoidesContados - newState.buenos) * 100 / newState.espermatozoidesContados)
      newState.totalFormasAnormales = round_decimal(newState.totalFormasAnormales, 2, this.props.auth.separadorDec, this.props.auth.separadorMil)
      newState.totalFormasAnormales = isNaN(newState.totalFormasAnormales) || !Number.isFinite(newState.totalFormasAnormales) ? 0 : newState.totalFormasAnormales
      this.props.change('espermatozoidesContados', newState.espermatozoidesContados)
      this.props.change('totalFormasAnormales', newState.totalFormasAnormales)      
      newState.acrosomas = 100 / newState.espermatozoidesContados * newState.acrosomasDannados
      if (isNaN(newState.acrosomas) || !isFinite(newState.acrosomas)) {
        newState.acrosomas = 0
      }
      this.props.change('acrosomas', newState.acrosomas)
      return newState;
    })
  }

  onChangeInputCuadrosContados (value) {
    if (value >= this.props.initialValues.cuadrosAContar) {
      if (this.state.limiteCuadrosContadosAlcanzado) {
        this.props.actions.yesNoModal('limiteCuadrosContadosAlcanzado', () => {
          setTimeout(() => {
            ReactDOM.findDOMNode(this.aceptarButtonRef).focus()
          }, 450) // Nota de Arturo: En mi equipo con menos tiempo de 400 no pone el foco e incluso con 400 a veces no lo pone (supongo que el navegador estara trantando el evento click del modal de aviso).
        })
      } else {
        this.setState({limiteCuadrosContadosAlcanzado: true}, () => { ReactDOM.findDOMNode(this.aceptarButtonRef).focus() })
      }
    } else if (this.state.limiteCuadrosContadosAlcanzado) {
      this.setState({limiteCuadrosContadosAlcanzado: false})
    }
  }

  submitContajeMaquinas (values) {
    this.props.actions.closeModalContajeMaquinas(this.props.from,this.state)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.props.reset()
      this.setState((state) => {
        return {
          ...this.props.initialValues
        }
      })
    }
    if (prevProps.initialValues.espermatozoidesContar !== this.props.initialValues.espermatozoidesContar) {
      this.setState((state) => {
        return {
          ...state,
          espermatozoidesContar: this.props.initialValues.espermatozoidesContar
        }
      }, () => {
        this.props.initialize(this.props.initialValues)
      })
    }
  }

  render () {
    const {
      t, handleSubmit, reset, showModal, tipoAnalisis, initialValues: {cuadrosAContar},
      teclasContaje: {acrosomasDannados, cabeza, cola, gotaDistal, gotaProximal, buenos, cuadrosContados, piezaIntermedia},
      combos: {comboDispositivosVideo},
      actions: {closeModalContajeMaquinasSuccess}
    } = this.props
    const tKey = 'ANALISIS_EXTRACCIONES.CONTAJE_MAQUINAS.'
    const maquinaContaje = tipoAnalisis && translateTipoAnalisisExtraccion[tipoAnalisis].replace('TIPO_ANALISIS_EXTRACCION.', '')

    return (
      <Modal show={showModal} onHide={() => closeModalContajeMaquinasSuccess()} dialogClassName="xl" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t(tKey + 'COMUN.TITLE', {maquinaContaje: t(tKey + maquinaContaje + '.TITLE')})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(this.submitContajeMaquinas.bind(this))} >
            <Row>
              <Col sm={3}>
                <Row>
                  <Field
                    colSm={12}
                    id="dispositivoVideo"
                    name="dispositivoVideo"
                    controlLabel={t(tKey + 'COMUN.FORM.DISPOSITIVO_VIDEO')}
                    component={InputSelect}
                    options={comboDispositivosVideo}
                    valueKey="value"
                    labelKey="label"
                  />
                </Row>
                <Row>
                  <Field
                    colSm={12}
                    id="acrosomasDannados"
                    name="acrosomasDannados"
                    controlLabel={t(tKey + 'COMUN.FORM.ACROSOMAS_DANNADOS')}
                    component={InputNumericalWithCountKeys}
                    numDecimales={0}
                    onInputChange={(event, value) => this.onChangeInputAcrosomasDannados(value)}
                    {...acrosomasDannados}
                    minValue={0}
                  />
                </Row>
                <Row>
                  <FormGroupSubtitle>
                    {t(tKey + 'COMUN.FORM.TECLAS_CONTAJE')}
                  </FormGroupSubtitle>
                  <Field
                    colSm={12}
                    id="cabeza"
                    name="cabeza"
                    controlLabel={t(tKey + 'COMUN.FORM.CABEZA')}
                    component={InputNumericalWithCountKeys}
                    numDecimales={0}
                    onInputChange={(event, value) => this.onChangeInputContajeMaquinas('cabeza', value)}
                    {...cabeza}
                    minValue={0}
                  />
                  <Field
                    colSm={12}
                    id="cola"
                    name="cola"
                    controlLabel={t(tKey + 'COMUN.FORM.COLA')}
                    component={InputNumericalWithCountKeys}
                    numDecimales={0}
                    onInputChange={(event, value) => this.onChangeInputContajeMaquinas('cola', value)}
                    {...cola}
                    minValue={0}
                  />
                  <Field
                    colSm={12}
                    id="gotaDistal"
                    name="gotaDistal"
                    controlLabel={t(tKey + 'COMUN.FORM.GOTA_DISTAL')}
                    component={InputNumericalWithCountKeys}
                    numDecimales={0}
                    onInputChange={(event, value) => this.onChangeInputContajeMaquinas('gotaDistal', value)}
                    {...gotaDistal}
                    minValue={0}
                  />
                  <Field
                    colSm={12}
                    id="gotaProximal"
                    name="gotaProximal"
                    controlLabel={t(tKey + 'COMUN.FORM.GOTA_PROXIMAL')}
                    component={InputNumericalWithCountKeys}
                    numDecimales={0}
                    onInputChange={(event, value) => this.onChangeInputContajeMaquinas('gotaProximal', value)}
                    {...gotaProximal}
                    minValue={0}
                  />
                  <Field
                    colSm={12}
                    id="piezaIntermedia"
                    name="piezaIntermedia"
                    controlLabel={t(tKey + 'COMUN.FORM.PIEZA_INTERMEDIA')}
                    component={InputNumericalWithCountKeys}
                    numDecimales={0}
                    onInputChange={(event, value) => this.onChangeInputContajeMaquinas('piezaIntermedia', value)}
                    {...piezaIntermedia}
                    minValue={0}
                  />
                  <Field
                    colSm={12}
                    id="buenos"
                    name="buenos"
                    controlLabel={t(tKey + 'COMUN.FORM.BUENOS')}
                    component={InputNumericalWithCountKeys}
                    numDecimales={0}
                    onInputChange={(event, value) => this.onChangeInputContajeMaquinas('buenos', value)}
                    {...buenos}
                    minValue={0}
                  />
                </Row>
              </Col>
              <Col sm={9}>
                <CamaraViewPage />
              </Col>
            </Row>
            <hr />
            <Row className="row-flex">
              <Col className={ tipoAnalisis === tipoAnalisisExtraccionConstants.CAMARA_BURKER ? 'col-sm-2' : "col-flex"}>
                <Button type="button" className="btn btn-primary btn-input-style" onClick={reset}>{t(tKey + 'COMUN.BUTTONS.REINICIAR')}</Button>
              </Col>
              {tipoAnalisis === tipoAnalisisExtraccionConstants.COLORIMETRO ? [
                <Field
                  customClass="col-flex"
                  colSm={0}
                  key="espermatozoidesContados"
                  id="espermatozoidesContados"
                  name="espermatozoidesContados"
                  controlLabel={t(tKey + 'COLORIMETRO.FORM.ESPERMATOZOIDES_CONTADOS')}
                  component={InputNumerical}
                  numDecimales={0}
                  disabled={true}
                />,
                <Field
                  customClass="col-flex"
                  colSm={0}
                  key="espermatozoidesContar"
                  id="espermatozoidesContar"
                  name="espermatozoidesContar"
                  controlLabel={t(tKey + 'COLORIMETRO.FORM.ESPERMATOZOIDES_A_CONTAR')}
                  component={InputNumerical}
                  numDecimales={0}
                  disabled={true}
                />
              ] : tipoAnalisis === tipoAnalisisExtraccionConstants.CAMARA_BURKER ? [
                <Field
                  colSm={3}
                  key="cuadrosContados"
                  id="cuadrosContados"
                  name="cuadrosContados"
                  controlLabel={t(tKey + 'CAMARA_BURKER.FORM.CUADROS_CONTADOS')}
                  component={InputNumericalWithCountKeys}
                  numDecimales={0}
                  onInputChange={(event, value) => this.onChangeInputCuadrosContados(value)}
                  {...cuadrosContados}
                  minValue={0}
                  maxValue={cuadrosAContar}
                />,
                <Field
                  customClass="col-flex"
                  colSm={0}
                  key="cuadrosAContar"
                  id="cuadrosAContar"
                  name="cuadrosAContar"
                  controlLabel={t(tKey + 'CAMARA_BURKER.FORM.CUADROS_A_CONTAR')}
                  component={InputNumerical}
                  numDecimales={0}
                  disabled={true}
                />
              ] : null}
              <Field
                customClass="col-flex"
                colSm={0}
                id="totalFormasAnormales"
                name="totalFormasAnormales"
                controlLabel={t(tKey + 'COMUN.FORM.TOTAL_FORMAS_ANORMALES')}
                component={InputNumerical}
                numDecimales={2}
                disabled={true}
              />
              <Field
                customClass="col-flex"
                colSm={0}
                id="acrosomas"
                name="acrosomas"
                controlLabel={t(tKey + 'COMUN.FORM.ACROSOMAS')}
                component={InputNumerical}
                numDecimales={2}
                disabled={true}
              />
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" forwardRef={this.setAceptarButtonRefFn} onClick={handleSubmit(this.submitContajeMaquinas)}>{t(tKey + 'COMUN.BUTTONS.ACEPTAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'modalContajeMaquinas',
  onSubmitFail
})(ModalContajeMaquinas))