import actionTypes from '../../constants/actions/analisisExtracciones/contajeMaquinas'

export function initialState () {
  return {
    showModal: false,
    tipoAnalisis: null,
    isSeguimiento: null,
    teclasContaje: {
      acrosomasDannados: {incrementarKey: 'r', disminuirKey: 't'},
      cabeza: {incrementarKey: 'n', disminuirKey: 's'},
      cola: {incrementarKey: 'v', disminuirKey: 'f'},
      gotaDistal: {incrementarKey: 'g', disminuirKey: 'h'},
      gotaProximal: {incrementarKey: 'c', disminuirKey: 'k'},
      buenos: {incrementarKey: 'l', disminuirKey: 'q'},
      cuadrosContados: {incrementarKey: 'i', disminuirKey: 'o'},
      piezaIntermedia: {incrementarKey: 'b', disminuirKey: 'v'}
    },
    initialValues: {
      acrosomasDannados: 0,
      cabeza: 0,
      cola: 0,
      gotaDistal: 0,
      gotaProximal: 0,
      buenos: 0,
      espermatozoidesContados: 0,
      espermatozoidesContar: 100,
      cuadrosContados: 0,
      cuadrosAContar: 40,
      totalFormasAnormales: 0,
      acrosomas: 0,
      piezaIntermedia: 0
    }
  }
}

function openModalContajeMaquinasSuccess (state, {tipoAnalisis, teclasContaje, isSeguimiento}) {
  return {
    ...state,
    showModal: true,
    tipoAnalisis,
    isSeguimiento,
    teclasContaje: {
      ...state.teclasContaje,
      ...teclasContaje
    }
  }
}

function closeModalContajeMaquinasSuccess (state) {
  return {
    ...state,
    showModal: false,
    tipoAnalisis: null
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.OPEN_MODAL_CONTAJE_MAQUINAS_SUCCESS:
      return openModalContajeMaquinasSuccess(state, action)
    case actionTypes.CLOSE_MODAL_CONTAJE_MAQUINAS_SUCCESS:
      return closeModalContajeMaquinasSuccess(state, action)
    default:
      return state
  }
}