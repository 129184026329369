import {select, put, call, all, takeLatest} from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { initialize, reset, change, getFormValues } from 'redux-form';
import i18n from '../../../i18n';
import {date_parser, round_decimal, dateTimeZones} from '../../../util/formatFunctions'
import {downloadDocument} from '../../../util/util'

// Constants
import {tipoAnalisisExtraccion as tipoAnalisisExtraccionConstants, translateTipoAnalisisExtraccion} from '../../../constants/analisis'
import {translateTipoControlControlMorfologico} from '../../../constants/controlMorfologico'
import actionTypes from '../../../constants/actions/planSanitario/controlCalidad/controlMorfologico'

// Actions
import {openSimpleModal, openMensajeAlerta} from '../../../actions/common'
import {
  fetchControlMorfologicoSuccess, openModalListadoControlMorfologicoSuccess, closeModalListadoControlMorfologicoSuccess,
  deSeleccionarTodosModalControlMorfologico, openModalFormControlMorfologicoSuccess, guardarModalFormControlMorfologicoSuccess,
  closeModalFormControlMorfologicoSuccess, fetchFiltrarModalInicioSuccess, closeModalInicio
} from '../../../actions/planSanitario/controlCalidad/controlMorfologico'
import {
  updateTokenDtvMainMagavisionSuccess
} from '../../../actions/analisisExtracciones/analisisExtracciones'
import {
  updateTokenDtvMagavisionPoolSuccess
} from '../../../actions/poolExtracciones/poolExtracciones'

// Services
import getControlMorfologicoListService from '../../../services/planSanitario/controlCalidad/getControlMorfologicoList'
import getFiltrarControlMorfologicoListService from '../../../services/planSanitario/controlCalidad/getFiltrarControlMorfologicoList'
import getFiltrarModalInicio from '../../../services/planSanitario/controlCalidad/getFiltrarModalInicio'
import deleteControlMorfologicoService from '../../../services/planSanitario/controlCalidad/deleteControlMorfologico'
import deleteSeleccionControlMorfologicoService from '../../../services/planSanitario/controlCalidad/deleteSeleccionControlMorfologico'
import getCrearCsvControlMorfologicoService from '../../../services/planSanitario/controlCalidad/getCrearCsvControlMorfologico'
import getImprimirPdfControlMorfologicoService from '../../../services/planSanitario/controlCalidad/getImprimirPdfControlMorfologico'
import getControlMorfologicoService from '../../../services/planSanitario/controlCalidad/getControlMorfologico'
import submitNuevoControlMorfologicoService from '../../../services/planSanitario/controlCalidad/submitNuevoControlMorfologico'
import savePrimerControlMorfologicoDelPoolService from '../../../services/planSanitario/controlCalidad/savePrimerControlMorfologicoDelPool'
import submitEditarControlMorfologicoService from '../../../services/planSanitario/controlCalidad/submitEditarControlMorfologico'
import iniciarServidorLecturaOldMagavisionService from '../../../services/analisisExtracciones/iniciarServidorLecturaOldMagavision'
import comprobarServidorLecturaOldMagavisionService from '../../../services/analisisExtracciones/comprobarServidorLecturaOldMagavision'
import leerDatosServidorLecturaOldMagavisionService from '../../../services/analisisExtracciones/leerDatosServidorLecturaOldMagavision'
import leerDatosServidorLecturaNewMagavisionService from '../../../services/analisisExtracciones/leerDatosServidorLecturaNewMagavision'

// Sagas
import {yesNoModal as yesNoModalSaga} from '../../modal/yesNoModal'
import {fetchTokenMagavision as fetchTokenMagavisionSaga} from '../../analisisExtracciones/analisisExtracciones'
import {cerrarNuevoMagavision as cerrarNuevoMagavisionSaga} from '../../analisisExtracciones/analisisExtracciones'
import {fetchTokenMagavisionPool as fetchTokenMagavisionPoolSaga} from '../../poolExtracciones/poolExtracciones'

export function * fetchControlMorfologico ({filtros}) {
  try {
    //console.log('sagas-fetchControlMorfologico',filtros)
    let auth = yield select(state => state.auth)
    let mainList = []
    mainList = yield call(getFiltrarControlMorfologicoListService, filtros, auth.token)
    // if (filtros && Object.keys(filtros).length > 1) {
    //   mainList = yield call(getFiltrarControlMorfologicoListService, filtros, auth.token)
    // } else {
    //   mainList = yield call(getControlMorfologicoListService)
    //   mainList = mainList.list
    // }

    yield put(fetchControlMorfologicoSuccess(mainList, filtros))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchControlMorfologico () {
  yield takeLatest(actionTypes.FETCH_CONTROL_MORFOLOGICO, fetchControlMorfologico)
}

export function * openModalListadoControlMorfologico ({idAnalisis, idPool}) {
  try {
    let auth = yield select(state => state.auth)
    let {list, conservacion, codigo} = yield call(getControlMorfologicoListService, idAnalisis, idPool)
    yield put(openModalListadoControlMorfologicoSuccess(list, conservacion, codigo, idAnalisis, idPool))
    
    if (idAnalisis && list.length > 0) {
      yield put(updateTokenDtvMainMagavisionSuccess(list[0].t_dtv_main, list[0].nombreVerracoPool))
    } else if (idPool && list.length > 0) {
      yield put(updateTokenDtvMagavisionPoolSuccess(list[0].t_dtv_main, list[0].nombreVerracoPool))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOpenModalListadoControlMorfologico () {
  yield takeLatest(actionTypes.OPEN_MODAL_LISTADO_CONTROL_MORFOLOGICO, openModalListadoControlMorfologico)
}

export function * closeModalListadoControlMorfologico () {
  try {
    let state = yield select(state => state)

    // Si se cierra el modal-listado desde el apartado principal de seguimientos, se recargara el listado con los filtros almacenados,
    // Si se cierra el modal-listado desde el modal de edición de analisis/pool, se actualizara el checkbox de seguimiento.
    if (state.seguimiento.esApartadoPrincipal) {
      yield call(fetchControlMorfologico, {filtros: state.seguimiento.filtros})
    } else {
      let newSeguimiento = state.seguimiento.list.length > 0
      let oldSeguimiento
      let formName = ''
      if (state.seguimiento.idAnalisis) {
        formName = 'formAnalisisExtraccion'
        oldSeguimiento = yield getFormValues(formName)(state).seguimiento
      } else if (state.seguimiento.idPool) {
        formName = 'formPoolExtraccion'
        oldSeguimiento = yield getFormValues(formName)(state).seguimiento
      }

      if (newSeguimiento !== oldSeguimiento) {
        yield put(change(formName, 'seguimiento', newSeguimiento))
      }
    }
    yield put(closeModalListadoControlMorfologicoSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCloseModalListadoControlMorfologico () {
  yield takeLatest(actionTypes.CLOSE_MODAL_LISTADO_CONTROL_MORFOLOGICO, closeModalListadoControlMorfologico)
}

export function * deleteControlMorfologico ({row}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      const {eliminado} = yield call(deleteControlMorfologicoService, row.idControlMorfologico, null)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }


      let state = yield select(state => state)
      yield call(fetchControlMorfologico, {filtros: state.controlMorfologico.filtros})
 
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteControlMorfologico () {
  yield takeLatest(actionTypes.DELETE_CONTROL_MORFOLOGICO, deleteControlMorfologico)
}

export function * deleteSeleccionControlMorfologico ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const {eliminado} = yield call(deleteSeleccionControlMorfologicoService, list, auth.token)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosModalControlMorfologico())
      yield put(initialize('listadoControlMorfologicoTable', {}))
      yield put(initialize('ControlMorfologicoTable', {}))

      
      let state = yield select(state => state)
      yield call(fetchControlMorfologico, {filtros: state.controlMorfologico.filtros})
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteSeleccionControlMorfologico () {
  yield takeLatest(actionTypes.DELETE_SELECCION_CONTROL_MORFOLOGICO, deleteSeleccionControlMorfologico)
}

export function * crearCsvControlMorfologico ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelControlMorfologico = yield call(getCrearCsvControlMorfologicoService, list, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(datosExcelControlMorfologico.excel)
    let name = i18n.t('MENU.PLAN_SANITARIO.CONTROL_CALIDAD.CONTROL_MORFOLOGICO') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCrearCsvControlMorfologico () {
  yield takeLatest(actionTypes.CREATE_CSV_CONTROL_MORFOLOGICO, crearCsvControlMorfologico)
}

export function * imprimirPdfControlMorfologico ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfControlMorfologico = yield call(getImprimirPdfControlMorfologicoService, list, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfControlMorfologico.pdf)
    let name = i18n.t('MENU.PLAN_SANITARIO.CONTROL_CALIDAD.CONTROL_MORFOLOGICO') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchImprimirPdfControlMorfologico () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_CONTROL_MORFOLOGICO, imprimirPdfControlMorfologico)
}

export function * openModalFormControlMorfologico ({idControlMorfologico}) {
  try {
    console.log('sagas-openModalFormControlMorfologico',idControlMorfologico)
    let state = yield select(state => state)
    let initialValues

    let data = {}
    if (idControlMorfologico) {
      data = yield call(getControlMorfologicoService, idControlMorfologico)
      console.log('sagas-openModalFormControlMorfologico0',data)
      initialValues = {...data}

      if (data.fechaControlMorfologico == null) {
        initialValues.fechaControlMorfologico = date_parser(new Date(), state.auth.formaFechaHora)
      }
      if (initialValues.tipoControl) {
        initialValues.tipoControl = state.combos.comboTipoControlSeguimiento.find((tipoControlItem) => {
          return tipoControlItem.value === initialValues.tipoControl
        })
        initialValues.tipoControl = {value: initialValues.tipoControl.value, label: i18n.t(`COMUN.COMBOS.${initialValues.tipoControl.label}`)}
      }
      if (data.idTipoAnalisis) {
        //initialValues.tipoAnalisis = data.idTipoAnalisis = parseInt(data.idTipoAnalisis, 10)
        initialValues.tipoAnalisis = state.combos.comboTipoAnalisisExtraccion.find((tipoControlItem) => {
          return tipoControlItem.value === data.idTipoAnalisis
        })
        initialValues.tipoAnalisis = {value: initialValues.tipoAnalisis.value, label: i18n.t(`COMUN.COMBOS.${translateTipoAnalisisExtraccion[initialValues.tipoAnalisis.value]}`)}
      }else{
        initialValues.tipoAnalisis = {value: state.panelControl.tipoAnalisisPredeterminado, label: i18n.t(`COMUN.COMBOS.${translateTipoAnalisisExtraccion[state.panelControl.tipoAnalisisPredeterminado]}`)}
      }
      if (data.idOperarioProcesado) {
          initialValues.operarioProcesado = state.combos.comboOperario.find((procesado) => {
            return procesado.value == data.idOperarioProcesado
          })
          initialValues.operarioProcesado = {value: initialValues.operarioProcesado.value, label: initialValues.operarioProcesado.label}
      }
    }
    yield put(initialize('ModalFormControlMorfologico', initialValues))
    yield put(openModalFormControlMorfologicoSuccess(data))
    yield call(fetchTokenMagavisionSaga)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOpenModalFormControlMorfologico () {
  yield takeLatest(actionTypes.OPEN_MODAL_FORM_CONTROL_MORFOLOGICO, openModalFormControlMorfologico)
}

export function * guardarModalFormControlMorfologico ({values}) {
  try {
    //console.log('sagas-guardarModalFormControlMorfologico', values)
    let state = yield select(state => state)
    let existControlMorfologico = !!(values && values.idControlMorfologico)
    let estadoPeticion

    if (existControlMorfologico) {
      estadoPeticion = yield call(submitEditarControlMorfologicoService, values, state.auth.token)
    } else {
      estadoPeticion = yield call(submitNuevoControlMorfologicoService, values, state.auth.token)
      // if(values.tipoAnalisis.value == tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION && state.poolExtracciones.pool.isPrimerControlMorfologicoDelPool){
      //   yield call(savePrimerControlMorfologicoDelPoolService, {idPool: values.idPool, t_dtv_main: state.poolExtracciones.pool.t_dtv_main}, state.auth.token)
      // }
    }
    yield put(guardarModalFormControlMorfologicoSuccess())

    estadoPeticion = estadoPeticion && (estadoPeticion.insertado || estadoPeticion.actualizado)
    if (estadoPeticion === true) {
      yield put(openMensajeAlerta(existControlMorfologico ? 'modificacion' : 'insercion'))
    } else {
      yield put(openMensajeAlerta(existControlMorfologico ? 'no-modificacion' : 'no-insercion'))
    }

    //recargamos
	  yield call(fetchControlMorfologico, {filtros: state.controlMorfologico.filtros})

  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchGuardarModalFormControlMorfologico () {
  yield takeLatest(actionTypes.GUARDAR_MODAL_FORM_CONTROL_MORFOLOGICO, guardarModalFormControlMorfologico)
}

export function * closeModalFormControlMorfologico () {
  try {
    yield put(closeModalFormControlMorfologicoSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCloseModalFormControlMorfologico () {
  yield takeLatest(actionTypes.CLOSE_MODAL_FORM_CONTROL_MORFOLOGICO, closeModalFormControlMorfologico)
}

export function * obtenerYGuardarResultadoSistemaAnalisisControlMorfologico ({tipoAnalisis}) {
  try {
    console.log('obtenerYGuardarResultadoSistemaAnalisisControlMorfologico',tipoAnalisis)
    let auth = yield select(state => state.auth)
    let estaServicioPreparado = false
    let resultMagavision = {}

    if (tipoAnalisis === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION) {
      console.log('NUEVO_MAGAVISION0:', tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION)
      let baseUrlMagavision = yield select(state => state.panelControl.urlMagavision)
      let {tokenUsuarioMagavision, t_anl} = yield select(state => state.analisisExtracciones)
      console.log('NUEVO_MAGAVISION1:', tokenUsuarioMagavision,t_anl)
      resultMagavision = yield call(leerDatosServidorLecturaNewMagavisionService, baseUrlMagavision, tokenUsuarioMagavision, t_anl)
      estaServicioPreparado = resultMagavision.code === 200
    }
    console.log('estaServicioPreparado', resultMagavision)
    if (estaServicioPreparado) {
      yield call(guardarResultadoSistemaAnalisisControlMorfologico, {data:resultMagavision, tipoAnalisis})
      yield call(cerrarNuevoMagavisionSaga, false)
    } else {
      yield call(yesNoModalSaga, {
        modalType: 'servidorNoPreparado'
      })
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchObtenerYGuardarResultadoSistemaAnalisisControlMorfologico () {
  yield takeLatest(actionTypes.OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS_CONTROL_MORFOLOGICO, obtenerYGuardarResultadoSistemaAnalisisControlMorfologico)
}

export function * fetchFiltrarModalInicio ({values}) {
  try {    
    console.log('sagas-fetchFiltrarModalInicio',values)
    const auth = yield select(state => state.auth)
    const list = yield call(getFiltrarModalInicio, values, auth.token)
    yield put(fetchFiltrarModalInicioSuccess(list,values))
    // if (Object.keys(list).length > 0) {
    //   yield put(abrirModalInicio())
    // }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchFiltrarModalInicio () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_MODAL_INICIO, fetchFiltrarModalInicio)
}

export function * anadirVerracosModalInicio ({listVerracosAsignados}) {
  try {
    //console.log('sagas-fetchFiltrarModalInicio',listVerracosAsignados)
    const auth = yield select(state => state.auth)
    const filtros = yield select(state => state.form.FiltrosModalAnnadirControlMorfologico.values)
    const status = yield call(submitNuevoControlMorfologicoService, listVerracosAsignados, auth.token)
    
    yield call(fetchControlMorfologico, {filtros})
    
    yield put(closeModalInicio())

    if (status.insertado === true) {
      yield put(openMensajeAlerta('insercion-planing'))
    } else {
      yield put(openMensajeAlerta('no-insercion-planing'))
    }

    // const list = yield call(getFiltrarModalInicio,  {values}, auth.token)
    // yield put(fetchFiltrarModalInicioSuccess(listVerracosAsignados))
    // if (Object.keys(list).length > 0) {
    //   yield put(abrirModalInicio())
    // }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchAnadirVerracosModalInicio () {
  yield takeLatest(actionTypes.ANADIR_VERRACOS_INICIO, anadirVerracosModalInicio)
}

export function* guardarResultadoSistemaAnalisisControlMorfologico({ data, tipoAnalisis }) {
  try {
    //console.log('guardarResultadoSistemaAnalisisControlMorfologico', data, tipoAnalisis)
    let state = yield select(state => state)
    let tipoAnalisisSeleccionado = tipoAnalisis || state.controlMorfologico.extraccion.tipoAnalisisSeleccionado || state.panelControl.tipoAnalisisPredeterminado

    if (
      tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.COLORIMETRO ||
      tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.CAMARA_BURKER
    ) {
      let faTotal = data.espermatozoidesContados - data.buenos
      let camposCalculados = {
        totalAcrosomas: data.acrosomasDannados,
        percentAcrosomas: data.acrosomas,
        faCabezas: data.cabeza,
        faCabezasPercent: data.cabeza !== 0 ? Math.round( ((data.cabeza * 100 / faTotal) * data.totalFormasAnormales / 100) *100)/100 : 0,
        faColas: data.cola,
        faColasPercent: data.cola !== 0 ? Math.round( ((data.cola * 100 / faTotal) * data.totalFormasAnormales / 100) *100)/100 : 0,
        faGotaDistal: data.gotaDistal,
        faGotaDistalPercent: data.gotaDistal !== 0 ? Math.round( ((data.gotaDistal * 100 / faTotal) * data.totalFormasAnormales / 100) *100)/100 : 0,
        faGotaProximal: data.gotaProximal,
        faGotaProximalPercent: data.gotaProximal !== 0 ? Math.round( ((data.gotaProximal * 100 / faTotal) * data.totalFormasAnormales / 100) *100)/100 : 0,
        faPiezaIntermedia: data.piezaIntermedia,
        faPiezaIntermediaPercent: data.piezaIntermedia !== 0 ? Math.round( ((data.piezaIntermedia * 100 / faTotal) * data.totalFormasAnormales / 100) *100)/100 : 0,
        faTotal,
        faTotalPercent: data.totalFormasAnormales,
        buenos: data.buenos
      }
      //console.log('guardarResultadoSistemaAnalisisControlMorfologico-1',camposCalculados)
      yield put(change('ModalFormControlMorfologico', 'totalAcrosomas', camposCalculados.totalAcrosomas))
      yield put(change('ModalFormControlMorfologico', 'faCabezas', camposCalculados.faCabezas))
      yield put(change('ModalFormControlMorfologico', 'faColas', camposCalculados.faColas))
      yield put(change('ModalFormControlMorfologico', 'faGotaProximal', camposCalculados.faGotaProximal))
      yield put(change('ModalFormControlMorfologico', 'faGotaDistal', camposCalculados.faGotaDistal))
      yield put(change('ModalFormControlMorfologico', 'faPiezaIntermedia', camposCalculados.faPiezaIntermedia))
      yield put(change('ModalFormControlMorfologico', 'faTotal', camposCalculados.faTotal))
      yield put(change('ModalFormControlMorfologico', 'totalAcrosomasPercent', camposCalculados.percentAcrosomas))
      yield put(change('ModalFormControlMorfologico', 'faCabezasPercent', camposCalculados.faCabezasPercent))
      yield put(change('ModalFormControlMorfologico', 'faColasPercent', camposCalculados.faColasPercent))
      yield put(change('ModalFormControlMorfologico', 'faGotaProximalPercent', camposCalculados.faGotaProximalPercent))
      yield put(change('ModalFormControlMorfologico', 'faGotaDistalPercent', camposCalculados.faGotaDistalPercent))
      yield put(change('ModalFormControlMorfologico', 'faPiezaIntermediaPercent', camposCalculados.faPiezaIntermediaPercent))
      yield put(change('ModalFormControlMorfologico', 'faTotalPercent', camposCalculados.faTotalPercent))

    } else if (
      tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION
    ) {
      let buenos = data.conteo - data.anormales
      let faCabezas = (data.anormales - data.cola - data.gproximal - data.gdistal)
      let faColas = data.cola
      let faGotaProximal = data.gproximal
      let faGotaDistal = data.gdistal
      let faTotalPercent = round_decimal(data.anormales * 100 / data.conteo)
      if (isNaN(faTotalPercent) || !Number.isFinite(faTotalPercent)) faTotalPercent = 0
    
      let camposCalculados = {
        buenos,
        faCabezas,
        faCabezasPercent: faCabezas !== 0 ? ((faCabezas * 100 / data.anormales) * faTotalPercent / 100) : 0,
        faColas,
        faColasPercent: faColas !== 0 ? ((faColas * 100 / data.anormales) * faTotalPercent / 100) : 0,
        faGotaProximal,
        faGotaProximalPercent: faGotaProximal !== 0 ? ((faGotaProximal * 100 / data.anormales) * faTotalPercent / 100) : 0,
        faGotaDistal,
        faGotaDistalPercent: faGotaDistal !== 0 ? ((faGotaDistal * 100 / data.anormales) * faTotalPercent / 100) : 0,        
        faTotal: data.anormales,
        faTotalPercent
      }
      // console.log('guardarResultadoSistemaAnalisisControlMorfologico-2', camposCalculados)
      yield put(change('ModalFormControlMorfologico', 'faColas', camposCalculados.faColas))
      yield put(change('ModalFormControlMorfologico', 'faGotaProximal', camposCalculados.faGotaProximal))
      yield put(change('ModalFormControlMorfologico', 'faGotaDistal', camposCalculados.faGotaDistal))  
      yield put(change('ModalFormControlMorfologico', 'faTotal', camposCalculados.faTotal))
      yield put(change('ModalFormControlMorfologico', 'faColasPercent', camposCalculados.faColasPercent))
      yield put(change('ModalFormControlMorfologico', 'faGotaProximalPercent', camposCalculados.faGotaProximalPercent))
      yield put(change('ModalFormControlMorfologico', 'faGotaDistalPercent', camposCalculados.faGotaDistalPercent)) 
      yield put(change('ModalFormControlMorfologico', 'faTotalPercent', camposCalculados.faTotalPercent))
    }
  } catch (error) {
    console.log(error)
  } finally {}
}

export function* watchGuardarResultadoSistemaAnalisisControlMorfologico() {
  yield takeLatest(actionTypes.GUARDAR_RESULTADO_SISTEMA_ANALISIS_CONTROL_MORFOLOGICO, guardarResultadoSistemaAnalisisControlMorfologico)
}
